import React, { useEffect, useState } from "react";
import { ErrorComponent, ProgressBar, ProgressDot, Step1, Step2, Step3, Step4, Step5, Step6 } from "../../components";
import { useMyContext } from "../../context/global/Context";
import { Label } from "../../components/basic";
import { formatNumber } from "../../utils/format";
import { About } from "../../components";
import { FAQ } from "../../components";
import { Contact } from "../../components";
import { isEmpty } from "../../utils/validation";
import Loading from "../../components/Loading/Loading";
import { defaultArraySteps } from "../../config";
import { useFetchInitialDataQuotationVehicle } from "../../hooks";
import moment from 'moment-timezone';

export default function QuotationVehicle() {
    const { vehicleData, activeStep, potencialPolicy, hashIframe, errorPolicy, quotationExpired, currentBranch } = useMyContext();

    const { vehicleUsages, fetchVehicleUsages, searchVehicleUsages } = useFetchInitialDataQuotationVehicle();

    const [errorView, setErrorView] = useState(false);

    const [loadingVehicleData, setLoadingVehicleData] = useState(true);

    const [blocked, setBlocked] = useState(false);

    const [startDate, setStartDate] = useState('');

    const startHour = '12:00 AM';

    useEffect(() => {
        const setCoverageDate = () => {
            const hour = parseInt(moment().tz('America/Argentina/Buenos_Aires').format('HH'));
            const minutes = parseInt(moment().tz('America/Argentina/Buenos_Aires').format('mm'));

            if (hour <= 11 && minutes <= 59) {
                setStartDate(moment().format('DD/MM/YYYY'));
            } else {
                const date = moment().add(1, 'day');

                setStartDate(date.format('DD/MM/YYYY'));
            }

        }

        setCoverageDate();

        const intervalHours = setInterval(setCoverageDate, 60000);

        return () => clearInterval(intervalHours);
    }, []);

    useEffect(() => {
        if (activeStep === 1 && vehicleData) {
            (async () => {
                if (vehicleData.QuoteType === 'P' && parseInt(currentBranch) === 3)
                    await fetchVehicleUsages(vehicleData.Infoauto.infoautoCodeByUsages[1]);
                else {
                    await searchVehicleUsages(parseInt(currentBranch), vehicleData.ModeloCodigo);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleData.Infoauto.infoautoCodeByUsages, vehicleData.QuoteType, vehicleData.ModeloCodigo, activeStep]);

    useEffect(() => {
        if (!isEmpty(vehicleData.QuoteType)) {
            setLoadingVehicleData(false);
        }

        if (!isEmpty(vehicleData.Vigency)) {
            setBlocked(vehicleData.Vigency);
        }
    }, [vehicleData]);

    useEffect(() => {
        if (errorPolicy) {
            setErrorView(true);
        }
    }, [errorPolicy]);

    useEffect(() => {
        if (quotationExpired === true) {
            window.open('/', '_self');
        }
    }, [quotationExpired]);

    let stepView = <></>;
    let titleView = <></>;

    switch (activeStep) {
        case 1:
            titleView = <><span className="font-bold">¡Comencemos!</span> Vamos a guiarte en cada paso</>
            stepView = <Step1 vehicleUsages={vehicleUsages} />
            break;
        case 2:
            titleView = <>Elegí la cobertura ideal para tu {vehicleData?.MarcaDescripcion}</>
            stepView = <Step2 />
            break;
        case 3:
            titleView = <>Completá tus datos</>
            stepView = <Step3 />
            break;
        case 4:
            titleView = <>¿Cómo deseas realizar la contratación?</>
            stepView = <Step4 />
            break;
        case 5:
            titleView = <>¿Cómo deseas realizar el pago?</>
            stepView = <Step5 />
            break;
        case 6:
            titleView = <></>
            stepView = <Step6 />
            break;
        default:
            stepView = <></>;
            break;
    }

    if (errorView) {
        return (
            <ErrorComponent />
        );
    }

    if (loadingVehicleData) {
        return <Loading size='lg' />
    }

    return (
        <>
            <div className="flex flex-col">
                {
                    hashIframe !== '' && activeStep === 5 ?
                        <></>
                        :
                        <>
                            <h1 className={`text-center title text-primary mt-3 mb-7 pb-2 text-3xl ${activeStep > 1 ? 'hidden md:block' : ''}`}>{titleView}</h1>
                            <div className={`hidden md:block ${blocked === true ? 'w-4/12' : 'w-2/3'} mx-auto mb-8`}>
                                <ProgressBar id="progress_bar_desktop">
                                    {defaultArraySteps.slice(0, blocked === true ? 2 : undefined).map((e, i) => {
                                        let active = ((i + 1) === activeStep) ? true : false;
                                        let prev = ((i + 1) < activeStep) ? true : false;

                                        let className = (active === true && prev === false) || (active === false && prev === true) ? 'text-primary font-semibold text-sm text-center mt-4 z-0 relative' : 'text-shiny-pink font-normal text-sm text-center mt-4 z-0 relative';

                                        return <ProgressDot reference="progress_bar_desktop" activeStep={active} label={<Label title={e.label} className={className} />} prev={prev} />
                                    })}
                                </ProgressBar>
                            </div>
                            <div className="grid grid-cols-3 md:hidden w-full my-4 relative">
                                <div className="flex flex-col items-center">
                                    {defaultArraySteps.slice(0, blocked === true ? 2 : undefined).map((e, i) => {
                                        let prev = ((activeStep - 1) === (i + 1)) ? true : false;

                                        if (prev === true)
                                            return <ProgressDot activeStep={false} label={<Label title={e.label} className={'text-ash-grey font-normal text-sm text-center mt-4 pt-1 z-0 relative'} />} prev={prev} inactiveColor='#AEB6C4' />
                                        else
                                            return <></>
                                    })}
                                </div>
                                {
                                    activeStep !== 1 ?
                                        (
                                            currentBranch === 3 ?
                                                <div className={`horizontal_line_prev absolute left-0 top-[12%] h-[2px] w-1/2 border-ash-grey bg-ash-grey z-0`}></div>
                                                :
                                                <div className={`horizontal_line_prev absolute left-0 top-[25%] h-[2px] w-1/2 border-ash-grey bg-ash-grey z-0`}></div>
                                        )
                                        :
                                        <></>
                                }
                                <div className="flex flex-col items-center">
                                    {defaultArraySteps.slice(0, blocked === true ? 2 : undefined).map((e, i) => {
                                        let active = ((i + 1) === activeStep) ? true : false;
                                        let prev = ((i + 1) < activeStep) ? true : false;

                                        let className = (active === true && prev === false) || (active === false && prev === true) ? 'text-primary font-bold text-sm text-center mt-4 z-0 relative' : 'text-shiny-pink font-bold text-sm text-center mt-4 z-0 relative';

                                        if (active === true)
                                            return <ProgressDot activeStep={active} label={<Label title={e.label} className={className} />} prev={prev} iconSize={60} />
                                        else
                                            return <></>
                                    })}
                                </div>
                                {
                                    activeStep !== defaultArraySteps.length ?
                                        (
                                            currentBranch === 3 ?
                                                <div className={`horizontal_line_prev absolute right-0 top-[12%] h-[2px] w-1/2 border-shiny-pink bg-shiny-pink z-0`}></div>
                                                :
                                                <div className={`horizontal_line_prev absolute right-0 top-[25%] h-[2px] w-1/2 border-shiny-pink bg-shiny-pink z-0`}></div>
                                        )
                                        :
                                        <></>
                                }
                                <div className="flex flex-col items-center">
                                    {defaultArraySteps.length - activeStep !== 0 && activeStep >= 3 ?
                                        <>
                                            <ProgressDot activeStep={false} label={<Label title={`${defaultArraySteps.length - activeStep === 1 ? 'Queda' : 'Quedan'} ${defaultArraySteps.length - activeStep} ${defaultArraySteps.length - activeStep === 1 ? 'paso' : 'pasos'}...`} className={'text-primary font-normal text-sm text-center mt-4 pt-1 z-0 relative'} />} prev={true} inactiveColor='#FFFFFF' className="-z-10" />
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        </>
                }
                {
                    activeStep > 1 ?
                        <div id="info_quotation_start" className={`w-full bg-primary ${(hashIframe !== '' && activeStep === 5) || activeStep === 6 ? 'hidden' : ''}`}>
                            <p className="text-center text-white py-4 md:py-3 px-10 md:px-0 font-semibold text-sm md:text-base">Tené en cuenta que tu cobertura tendrá vigencia a partir del <span className="font-bold">{startDate}</span> a las <span className="font-bold">{startHour}</span></p>
                        </div>
                        :
                        <></>
                }
                <>
                    <div className="container xl:w-3/4 mx-auto mb-20">
                        {stepView}
                    </div>
                    {potencialPolicy !== null && activeStep === 3 ?
                        <div className="flex flex-col md:flex-row justify-center w-full bg-white-pink py-8 items-center gap-6">
                            <div className="flex flex-col">
                                <h5 className="text-deep-wine-red uppercase font-semibold text-lg text-center md:text-start">Cobertura seleccionada</h5>
                                <h4 className="text-crimson-red uppercase font-semibold text-2xl text-center md:text-start">{potencialPolicy?.coverageTopTitle} {potencialPolicy?.coverageBottomTitle}</h4>
                            </div>
                            <div className="flex flex-col text-center">
                                <h5 className="text-primary text-lg font-semibold line-through">{formatNumber(potencialPolicy?.cashFeeValue, 'es-AR', 'currency', 'ARS')} /mes</h5>
                                <h5 className="text-black text-4xl font-semibold">{formatNumber(potencialPolicy?.feeValue, 'es-AR', 'currency', 'ARS')} /mes</h5>
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {activeStep === 1 ?
                        <>
                            <About />
                            <FAQ />
                        </>
                        :
                        activeStep === 6 ? <FAQ /> : <></>
                    }
                    <Contact />
                </>
            </div>
        </>
    );
}
