import ReactGA from "react-ga4";
import { TAG_MANAGER_KEY as TRACKING_ID } from "../config/constants";

const pageViewEvent = (page, title) => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: page, title: title });
}

const purchaseEvent = (item) => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.event('purchase', item);
}

const addPaymentInfoEvent = (item) => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.event('add_payment_info', item);
}

const addToCartEvent = (item) => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.event('add_to_cart', item);
}

const logViewItemListEvent = (items) => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.event('view_item_list', items);
}

const beginCheckoutEvent = (item) => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.event('begin_checkout', item);
}

const refundEvent = () => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.event('refund', { currency: 'ARS', transaction_id: Math.random().toString(36).substring(2, 18), value: 1 });
}

export { pageViewEvent, purchaseEvent, addPaymentInfoEvent, addToCartEvent, logViewItemListEvent, beginCheckoutEvent, refundEvent };