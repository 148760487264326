import React, { useEffect, useState } from 'react';
import './Modal.css';
import PropTypes from 'prop-types';
import { ArrowLeft, Close } from '../../assets/icons';
import { Input, Button, Label } from '../basic';
import { usePersonalData } from '../../hooks';
import { onlyLetter, onlyNumber, validatePhoneNumber } from '../../utils/validation';

const Modal = ({ children, showFunction, show, type = 'mail', title = 'Completa tus datos para enviar tu cotización.', handleConfirm, handleClose = () => { } }) => {
	const { dataPerson, modifyDataPerson } = usePersonalData();

	const [sent, setSent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (show) {
			document.getElementsByTagName('html')[0].style.overflow = "hidden";
		} else {
			document.getElementsByTagName('html')[0].style.overflow = "auto";
		}
	}, [show]);

	const styleInputPersonalData = 'border-2 border-b-shiny-pink border-t-shiny-pink-lighter border-x-shiny-pink-lighter bg-shiny-pink-lighter';

	const handleConfirmButton = async (obj = null) => {
		setIsLoading(true);

		if (handleConfirm.constructor.name === 'Function') {
			setIsLoading(false);
			handleConfirm(obj);
		} else {
			await handleConfirm(obj)
				.then(() => {
					setIsLoading(false);
				});
		}
	}

	const handleChangeInput = async (type, value) => {
		switch (type) {
			case 'nombre':
				if (onlyLetter(value)) modifyDataPerson({ Nombre: value });
				break;
			case 'apellido':
				if (onlyLetter(value)) modifyDataPerson({ Apellido: value });
				break;
			case 'area':
				if (validatePhoneNumber(value) && onlyNumber(value)) modifyDataPerson({ Area: value });
				break;
			case 'telefono':
				if (validatePhoneNumber(value) && onlyNumber(value)) modifyDataPerson({ Telefono: value });
				break;
			case 'email':
				modifyDataPerson({ EMail: value });
				break;
			default:
				break;
		}
	}

	const handleDownloadModal = async () => {
		switch (type) {
			case 'personal_data':
				handleConfirmButton(dataPerson);
				break;
			default:
				setSent(true);
				break;
		}
	}

	function renderType(types) {
		switch (types) {
			case 'info':
			case "confirm":
				return setSent(true);
			case "mail":
				return <Input className={''} size='w-full' type='text' name='mail' placeholder={'Ingresá tu correo electrónico'} border='border-[1.5px] border-shiny-pink rounded' handleChange={() => console.log('mail')} />;
			case "cel":
				return <Input className={''} size='w-full' type='phone' name='phone' placeholder={'Ingresá tu número de teléfono'} border='border-[1.5px] border-shiny-pink rounded' handleChange={() => console.log('phone')} />;
			case "personal_data":
				return (
					<div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
						<Input type='text' label={<Label className={'text-left mb-1 md:ml-4 font-bold text-sm text-shiny-pink text-nowrap uppercase'} title={'nombre *'} />} placeholder={''} border={styleInputPersonalData} className={''} size='w-full' value={dataPerson.Nombre ?? ''} handleChange={(e) => { handleChangeInput('nombre', e.target.value) }} required maxLength={100} />
						<Input type='text' label={<Label className={'text-left mb-1 md:ml-4 font-bold text-sm text-shiny-pink text-nowrap uppercase'} title={'apellido *'} />} placeholder={''} border={styleInputPersonalData} className={''} size='w-full' value={dataPerson.Apellido ?? ''} handleChange={(e) => { handleChangeInput('apellido', e.target.value) }} required maxLength={100} />
						<div className="grid grid-cols-4 md:flex gap-4">
							<div className="w-fit md:w-min">
								<Input name='area_phone' type='text' label={<Label className={'text-left mb-1 md:ml-4 font-bold text-sm text-shiny-pink text-nowrap uppercase'} title={'área *'} />} placeholder={''} className={'border-2 border-b-shiny-pink border-t-shiny-pink-lighter border-x-shiny-pink-lighter w-full px-4 font-bold py-3 placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded focus:outline-none bg-shiny-pink-lighter'} size={''} value={dataPerson.Area ?? ''} handleChange={(e) => { handleChangeInput('area', e.target.value) }} required />
							</div>
							<div className="w-full col-span-3">
								<Input name='number_phone' type='text' label={<Label className={'text-left mb-1 md:ml-4 font-bold text-sm text-shiny-pink text-nowrap uppercase'} title={'teléfono *'} />} placeholder={''} className={'border-2 border-b-shiny-pink border-t-shiny-pink-lighter border-x-shiny-pink-lighter bg-shiny-pink-lighter w-full px-4 font-bold py-3 placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded  focus:outline-none'} value={dataPerson.Telefono ?? ''} handleChange={(e) => { handleChangeInput('telefono', e.target.value) }} required />
							</div>
						</div>
						<Input type='email' label={<Label className={'text-left mb-1 md:ml-4 font-bold text-sm text-shiny-pink text-nowrap uppercase'} title={'correo electrónico *'} />} placeholder={''} border={styleInputPersonalData} className={''} size='w-full' value={dataPerson.EMail ?? ''} handleChange={(e) => { handleChangeInput('email', e.target.value) }} required maxLength={100} />
					</div>
				)
			default:
				break;
		}
	}

	const renderView = {
		'confirm': <div className='py-4 text-maroon text-xl font-semibold text-center px-10 md:px-14'>{title}</div>,
		'info': <div className='py-4 text-primary text-lg font-semibold text-center px-10 md:px-14'>{title}</div>,
		'personal_data': <div className='py-4 text-primary text-lg font-semibold text-center px-10 md:px-14'>{title}</div>,
		'default':
			<>
				<div className='py-4 text-maroon text-xl font-semibold'>Listo!</div>
				<div className='py-4 text-maroon text-xl font-semibold'>Tu cotización ha sido enviada correctamente</div>
			</>
	}

	if (children) {
		return (
			<div className={`modal bg-black-op z-50 top-0 bottom-0 left-0 right-0 ${show ? 'visible fixed' : 'hidden'} px-6 md:px-0`}>
				<div className="w-full h-full flex justify-center items-center">
					<div className="bg-shiny-pink-lighter md:w-[611px] h-fit max-h-[85%] rounded-2xl border-2 border-maroon overflow-auto">
						<div className='w-full flex justify-end items-center sticky top-0 bg-shiny-pink-lighter z-10'>
							<button className='p-2.5' onClick={() => showFunction(false)}>
								<Close color={'#7C1338'} size={28} opacity={1.0} />
							</button>
						</div>
						<div className={`flex items-center w-full h-full flex-col py-2`}>
							{children}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className={`modal bg-black-op z-50 top-0 bottom-0 left-0 right-0 ${show ? 'visible fixed' : 'hidden'} px-10 md:px-0`} >
				<div className='w-full h-full flex justify-center items-center'>
					<div className={`bg-shiny-pink-lighter w-[611px] h-fit max-h-[85%] rounded-2xl border-2 border-maroon overflow-auto`}>
						<div className='w-full flex justify-end items-center sticky top-0 bg-shiny-pink-lighter z-10'>
							<button className='p-2.5' onClick={() => showFunction(false)}>
								<Close color={'#7C1338'} size={28} opacity={1.0} />
							</button>
						</div>
						<div className={`flex ${sent ? 'justify-around' : 'justify-between'} items-center w-full h-full flex-col py-2`}>
							{!sent ?
								<>
									<div className={`py-2 px-6 md:px-0 md:py-4 text-maroon text-lg md:text-xl font-semibold md:mt-4 text-center`}>{title}</div>
									<div className={`py-5 ${type === 'personal_data' ? 'w-full px-4 md:px-6' : 'w-[340px]'}`}>
										{renderType(type)}
									</div>
								</>
								:
								renderView[type] || renderView['default']
							}
							<div className={`flex items-center ${type === "confirm" ? 'flex-col md:flex-row-reverse justify-between gap-3 md:gap-8' : 'flex-col justify-end w-full md:w-auto pb-2 md:pb-0'}`}>
								{!sent &&
									<div className='md:py-5 w-full md:w-[290px] px-16 md:px-0'>
										<Button type={'primary'} title="descargar" className={'rounded-lg border-2 py-2 font-semibold text-base uppercase mb-3 mt-2 hover:bg-opacity-85'} size={'full'} handleClick={handleDownloadModal} loading={isLoading} />
									</div>
								}
								{
									type === 'confirm' && <Button type={'primary'} title="confirmar" className={'rounded-lg border-2 py-2 font-semibold text-base uppercase mb-0 md:mb-3 mt-2 hover:bg-opacity-85'} size={'sm'} handleClick={handleConfirmButton} loading={isLoading} />
								}
								{
									type === 'confirm' ?
										<button className={`flex flex-row items-center text-marooncursor-pointer mb-3 md:mb-0`} onClick={handleClose}>
											Cerrar <ArrowLeft color={'#7C1338'} size={24} opacity={1.0} />
										</button>
										:
										<button className={`flex flex-row items-center text-marooncursor-pointer mb-3 mt:3 md:mt-0 md:mb-4`} onClick={() => showFunction(false)}>
											Cerrar <ArrowLeft color={'#7C1338'} size={24} opacity={1.0} />
										</button>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

Modal.propTypes = {
	showFunction: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	type: PropTypes.string,
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	handleConfirm: PropTypes.func
};

export default Modal;