import React, { useEffect } from 'react';
import './Carousel.css';
import PropTypes from 'prop-types';

const Carousel = ({ children, indicators = false, arrows = false, autoSlide = false, duration = 5, className = '' }) => {
	const childrenArray = React.Children.map(children, (child, index) => {
		return (
			<React.Fragment>
				<div key={`carousel_item_${index}`} className={`carousel_item ${index === 0 ? '' : 'hidden'} duration-1000 ease-in`} data-carousel-item={index}>
					{React.cloneElement(child)}
				</div>
			</React.Fragment>
		);
	});

	const handleItemShown = (indice, idx) => {
		document.querySelector(`.carousel_item[data-carousel-item="${indice}"]`).classList.add('hidden');
		document.querySelector(`.carousel_item[data-carousel-item="${idx}"]`).classList.remove('hidden');

		if (indicators === true) {
			document.querySelector(`button[data-carousel-slide-to="${indice}"]`).classList.remove('bg-black');
			document.querySelector(`button[data-carousel-slide-to="${indice}"]`).classList.add('bg-light-grey');
			document.querySelector(`button[data-carousel-slide-to="${idx}"]`).classList.remove('bg-light-grey');
			document.querySelector(`button[data-carousel-slide-to="${idx}"]`).classList.add('bg-black');
		}
	}

	useEffect(() => {
		if (autoSlide === true && children.length > 1) {
			const intervalCarousel = setInterval(function () {
				let indice = Array.from(document.querySelector('#default-carousel .item_container').children).indexOf(document.querySelector(`.carousel_item[data-carousel-item]:not(.hidden)`));

				let idx;
				if (indice === children.length - 1) {
					idx = 0;
				} else {
					idx = indice + 1;
				}

				handleItemShown(indice, idx);
			}, duration * 1000);

			return () => {
				clearInterval(intervalCarousel);
			};
		}
	}, [autoSlide, duration, children.length]);

	const handleClickIndicators = (idx) => {
		let indice = Array.from(document.querySelector('#default-carousel .item_container').children).indexOf(document.querySelector(`.carousel_item[data-carousel-item]:not(.hidden)`));

		if (typeof idx === 'number') {
			if (indice !== idx) {
				handleItemShown(indice, idx);
			}
		} else if (typeof idx === 'string') {
			let idxAux = 0;

			switch (idx) {
				case 'prev':
					if (indice === 0) {
						idxAux = children.length - 1;
					} else {
						idxAux = indice - 1;
					}
					break;
				case 'next':
					if (indice === children.length - 1) {
						idxAux = 0;
					} else {
						idxAux = indice + 1;
					}
					break;
				default:
					idxAux = indice;
					break;
			}

			handleItemShown(indice, idxAux);
		}
	}

	return (
		<div className={className !== '' ? className : `max-w-2xl mx-auto`}>
			<div id="default-carousel" className="carousel relative" data-carousel="static">
				<div className="item_container overflow-hidden relative rounded-lg h-auto">
					{childrenArray}
				</div>
				{
					indicators === true ?
						<div className="flex absolute bottom-5 left-1/2 z-30 space-x-3 -translate-x-1/2">
							{
								children.map((child, idx) => {
									return (
										<button key={`button_slide_${idx}`} type="button" className={`w-3 h-3 rounded-full ${idx === 0 ? 'bg-black' : 'bg-light-grey'}`} aria-current="false" data-carousel-slide-to={idx} onClick={() => { handleClickIndicators(idx) }}></button>
									);
								})
							}
						</div>
						:
						<></>
				}
				{
					arrows === true ?
						<React.Fragment>
							<button type="button" className="flex absolute top-0 left-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" data-carousel-prev onClick={() => { handleClickIndicators('prev') }}>
								<span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
									<svg className="w-5 h-5 text-maroon sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
									<span className="hidden">Previous</span>
								</span>
							</button>
							<button type="button" className="flex absolute top-0 right-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" data-carousel-next onClick={() => { handleClickIndicators('next') }}>
								<span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
									<svg className="w-5 h-5 text-maroon sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
									<span className="hidden">Next</span>
								</span>
							</button>
						</React.Fragment>
						:
						<></>
				}
			</div>
		</div>
	);
};

Carousel.propTypes = {
	indicators: PropTypes.bool,
	arrows: PropTypes.bool,
	autoSlide: PropTypes.bool,
	duration: PropTypes.number,
	className: PropTypes.string,
};

export default Carousel;