import React from 'react';
import './PlanContainer.css';
import PropTypes from 'prop-types';
import { DoubleCheck } from '../../assets/icons';
import { Button, Select } from '../basic';
import { isEmpty } from '../../utils/validation';

const PlanContainer = ({ id, title, secondTitle, bonusText, subtitle, info, oldPrice, newPrice, btnText, recomended, size, select, setSelectedCoverage, btnDisabled }) => {
	return (
		<div className={`plancontainer ${size} flex mx-auto rounded-2xl border-[3px] justify-between flex-col mt-0 items-start ${recomended ? 'border-shiny-pink' : 'border-shiny-pink-lighter md:border-white bg-shiny-pink-lighter md:bg-white'}`}>
			<div className={`w-full py-4 px-4 bg-shiny-pink rounded-t-xl h-auto ${recomended ? 'bg-shiny-pink text-white' : 'bg-shiny-pink-lighter md:bg-white text-maroon'}`}>
				{recomended &&
					<div className='cobertura_recomendada uppercase text-xs leading-6 tracking-normal text-center text-white'>
						cobertura recomendada
					</div>
				}
				<div className={`text-[1.9rem] font-semibold leading-10 tracking-normal text-center ${recomended ? 'md:pb-0' : ''}`}>
					{title}{!isEmpty(secondTitle) ? <><br></br><span className='text-[20px]'>{secondTitle}</span></> : <></>}
				</div>
			</div>
			<div className={`w-full px-4 py-4 h-full rounded-b-xl flex flex-col items-center justify-between ${recomended ? 'bg-white-pink-lighter' : 'bg-shiny-pink-lighter md:bg-white'}`}>
				<div className='m-auto 2md:flex flex-col justify-start items-center h-full hidden md:mb-10'>
					<div className='text-sm font-normal leading-6 tracking-wide text-center text-black mb-3'>
						{subtitle}
					</div>
					{info.map(i => {
						return (
							<div className='flex w-full flex-row gap-2'>
								<DoubleCheck size={16} color={'#DE6180'} opacity={1.0} />
								<div className='font-roboto text-sm font-semibold leading-5 tracking-normal text-left text-black'>
									{i.title}
								</div>
							</div>)
					})}

				</div>

				<div className='m-auto w-full flex flex-col h-full justify-end items-center'>
					{select !== undefined ?
						<Select list={select} className={'p-2.5 border-black border rounded text-grey font-normal w-full my-3'} />
						: <></>
					}
					{
						!isEmpty(bonusText) ?
							<p className='w-full text-center text-sm 3xl:text-base font-semibold text-black md:mb-3'>{bonusText}</p>
							:
							<></>
					}
					{oldPrice !== undefined ?
						<del className='font-open-sans text-[20px] font-semibold leading-7 tracking-tighter text-center text-[#BB7E8E]'>
							{oldPrice} /mes
						</del>
						: <></>
					}

					<div className='flex flex-row gap-2 justify-center items-baseline'>
						<div className="font-open-sans text-4xl font-semibold leading-14 tracking-tighter text-right">
							{newPrice}
						</div>
						<div className="font-open-sans text-base font-normal leading-6 tracking-wide text-left">
							/mes
						</div>
					</div>
					<div className="font-open-sans text-sm font-normal leading-5 tracking-tighter text-center text-grey mt-2 mb-5">
						Valor estimado para nuevas pólizas
					</div>
					<Button size={'full'} className={`text-white ${isEmpty(btnText) ? 'bg-shiny-pink border-shiny-pink' : 'bg-deep-crimson border-deep-crimson'} rounded-lg border-2 py-2 font-semibold text-sm 3xl:text-base hover:bg-opacity-85 hover:border-opacity-5`} title={isEmpty(btnText) ? 'CONTRATAR PLAN' : btnText} handleClick={() => { setSelectedCoverage(id) }} disabled={btnDisabled} />
				</div>
			</div>


		</div>
	);
};

PlanContainer.propTypes = {
	id: PropTypes.number,
	title: PropTypes.string,
	secondTitle: PropTypes.string,
	bonusText: PropTypes.string,
	subtitle: PropTypes.string,
	info: PropTypes.array,
	oldPrice: PropTypes.string,
	newPrice: PropTypes.string,
	btnText: PropTypes.string,
	recomended: PropTypes.bool,
	size: PropTypes.string,
	select: PropTypes.element,
	setSelectedCoverage: PropTypes.func
};

export default PlanContainer;