"use client";
import React, { useEffect } from 'react';
import './BenefitsComponent.css';
import PropTypes from 'prop-types';
import { useMyContext } from '../../context/global/Context';
import { Close, DoubleCheck } from '../../assets/icons';

const BenefitsComponent = ({ show = false, showFunction = () => { } }) => {

	const { potencialPolicy } = useMyContext();

	useEffect(() => {
		if (show) {
			document.getElementsByTagName('html')[0].style.overflow = "hidden";
		} else {
			document.getElementsByTagName('html')[0].style.overflow = "auto";
		}
	}, [show]);

	const benefitsItems = (
		<>
			{
				potencialPolicy.benefits.length > 0 ?
					<div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-x-3 md:gap-y-4">
						{
							potencialPolicy.benefits.map((benefit, idx) => {
								if (benefit.hasBenefit === 1) {
									return (
										<>
											<div className="md:flex flex-row items-center hidden">
												<DoubleCheck size={12} color={'#DE6180'} />
												<p className="text-black font-semibold text-xs text-start ml-2">{benefit.description}</p>
											</div>
											<div className="flex flex-row items-center md:hidden">
												<DoubleCheck size={20} color={'#DE6180'} />
												<p className="text-black font-semibold text-xs text-start ml-2">{benefit.description}</p>
											</div>
										</>
									)
								} else return <></>
							})
						}
					</div>
					:
					<></>
			}
		</>
	);

	return (
		<div className={`${show ? 'visible fixed flex justify-center' : 'hidden'} top-0 bottom-0 right-0 left-0 z-50 bg-white bg-opacity-85 items-center`}>
			<div className='w-[82%] min-w-[82%] md:w-[50%] md:min-w-[50%] h-[80%] md:h-fit md:max-h-[80%] flex justify-center items-center relative'>
				<div onClick={() => { showFunction(false) }}>
					<Close size={26} color='#7C1338' className='absolute top-2 right-2 cursor-pointer' />
				</div>
				<div className="grid grid-cols-1 items-center h-full bg-shiny-pink-lighter border-primary border-2 rounded-lg">
					<div className="flex flex-col text-center">
						<h4 className="text-primary uppercase font-semibold text-lg md:text-2xl py-8 px-8 md:px-0 md:py-2 text-pretty">{potencialPolicy?.coverageTopTitle} {potencialPolicy?.coverageBottomTitle}</h4>
					</div>
					<div className={`modal_benefits w-full h-full overflow-auto px-4 pb-4 md:px-4 md:pb-6 bg-opacity-85 md:mt-4`}>
						{benefitsItems}
					</div>
				</div>
			</div>
		</div>
	);
};

BenefitsComponent.propTypes = {
	show: PropTypes.bool,
	showFunction: PropTypes.func
};

export default BenefitsComponent;