import React from 'react';
import { useMyContext } from '../../context/global/Context';
import { formatNumber } from '../../utils/format';
import './QuoteContract.css';
import PropTypes from 'prop-types';
import { DownArrow } from '../../assets/icons';

const QuoteContract = ({ handleSelectContract = () => { }, showFunction = () => { } }) => {

	const { potencialPolicy } = useMyContext();

	return (
		<div className='quotecontract mt-6 md:mt-24'>
			<div className="flex md:flex-row flex-col md:border-primary border-white border-2 rounded-xl justify-center items-center md:py-11 py-8 px-10 md:gap-14 gap-6">
				<div className="flex flex-col text-center">
					<h5 className="text-deep-wine-red uppercase font-bold text-xl md:text-3xl 2sm:text-2xl whitespace-nowrap">Cobertura seleccionada</h5>
					<h4 className="text-crimson-red uppercase font-semibold text-2xl md:text-4xl 2sm:text-3xl">{potencialPolicy?.coverageTopTitle} {potencialPolicy?.coverageBottomTitle}</h4>
					<div className="flex flex-col mt-3 md:mt-4">
						<p className='uppercase text-primary font-semibold text-sm cursor-pointer inline-flex justify-center' onClick={() => { showFunction(true) }}>detalle de la cobertura<DownArrow color='#7C1338' size={14} className='ms-3' /> </p>
					</div>
				</div>
				<div className="flex flex-col text-center">
					<h5 className="text-primary 2sm:text-base md:text-lg text-sm font-semibold line-through whitespace-nowrap">{formatNumber(potencialPolicy?.cashFeeValue, 'es-AR', 'currency', 'ARS')} /mes</h5>
					<h5 className="text-[#000000] text-2xl md:text-5xl 2sm:text-4xl font-semibold whitespace-nowrap my-2">{formatNumber(potencialPolicy?.feeValue, 'es-AR', 'currency', 'ARS')} /mes</h5>
					<p className="text-[#000000] md:text-base 2sm:text-sm text-xs font-normal">20% de descuento por débito automático</p>
				</div>
			</div>
		</div>
	);
};

QuoteContract.propTypes = {
	handleSelectContract: PropTypes.func,
	showFunction: PropTypes.func,
};

export default QuoteContract;