import { isEmpty } from "../../utils/validation";

const lStorage = async (key, data) => {
    try {
        localStorage.setItem(key, JSON.stringify(data));

        return true;
    } catch (e) {
        console.error(e);

        return false;
    }
}

const sStorage = (key, data) => {
    try {
        sessionStorage.setItem(key, JSON.stringify(data));

        return true;
    } catch (e) {
        console.error(e);

        return false;
    }
}

const getItemStorage = (type = 'L' || 'S', key) => {
    let data;

    switch (type) {
        case 'L':
            data = localStorage.getItem(key);
            break;
        case 'S':
            data = sessionStorage.getItem(key);
            break;
        default:
            data = null;
            break;
    }

    try {
        return JSON.parse(data);
    } catch (e) {
        return null;
    }
}

const removeItemStorage = (type = 'L' || 'S', key) => {
    switch (type) {
        case 'L':
            localStorage.removeItem(key);
            break;
        case 'S':
            sessionStorage.removeItem(key);
            break;
        default:
            break;
    }
}

export { lStorage, sStorage, getItemStorage, removeItemStorage };