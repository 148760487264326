import React from 'react';
import './Label.css';
import PropTypes from 'prop-types';

const Label = ({ title, icon, className, htmlFor }) => {
	return (
		<div className='flex label_component'>
			{icon}
			{htmlFor !== undefined ? <label htmlFor={htmlFor} className={className}>{title}</label> : <label className={className}>{title}</label>}
		</div>
	);
};

Label.propTypes = {
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.element
	]),
	icon: PropTypes.element,
	className: PropTypes.string,
	htmlFor: PropTypes.string
};

export default Label;