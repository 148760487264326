import React, { useEffect, useState } from 'react';
import './FAQ.css';
import Accordion from '../Accordion/Accordion';
import { Car, Motorbike, Checklist, Money, Laptop } from '../../assets/icons';
import { useMyContext } from '../../context/global/Context';
import data from '../../services/faq-content.json';
import { useLocation } from 'react-router-dom';

const FAQ = () => {
	const location = useLocation();

	const { currentBranch } = useMyContext();

	const [topic, setTopic] = useState(0);
	const [faq, setFaq] = useState(data);

	useEffect(() => {
		if (location.pathname !== "/") {
			data.map((e, i) => {
				switch (currentBranch) {
					case 3:
						handleIconClick(1);
						setTopic(1);
						break;
					case 4:
						handleIconClick(2);
						setTopic(2);
						break;
					default:
						handleIconClick(0);
						setTopic(0);
						break;
				}
			});
		} else {
			handleIconClick(0);
			setTopic(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentBranch, location.pathname]);

	const handleIconClick = (index) => {
		const updatedFaq = faq.map((item, i) => ({
			...item,
			current: i === index ? !item.current : false,
		}));

		setFaq(updatedFaq);
	};

	function renderIcon(str) {

		switch (str) {
			case "<Checklist size={32} opacity={1.0} color={'#7C1338'}/>":
				return <Checklist size={32} opacity={1.0} color={'#7C1338'} />;

			case "<Car size={32} opacity={1.0} color={'#7C1338'}/>":
				return <Car size={32} opacity={1.0} color={'#7C1338'} />;

			case "<Motorbike size={32} opacity={1.0} color={'#7C1338'}/>":
				return <Motorbike size={32} opacity={1.0} color={'#7C1338'} />;

			case "<Laptop size={32} opacity={1.0} color={'#7C1338'}/>":
				return <Laptop size={32} opacity={1.0} color={'#7C1338'} />;

			case "<Money size={32} opacity={1.0} color={'#7C1338'}/>":
				return <Money size={32} opacity={1.0} color={'#7C1338'} />;

			default:
				return <></>
		}
	}

	return (
		<div id='preguntas_frecuentes' className='faq bg-white w-full min-h-[500px]'>
			<div className='py-6 lg:w-3/4 xl:w-10/12 w-11/12 2xl:w-11/12 m-auto justify-center items-center'>
				<div className='flex flex-col justify-center items-center'>
					<div className='flex flex-col text-center py-10'>
						<div>
							<h2 className='text-primary text-2xl lg:text-3xl font-bold leading-7'>Preguntas Frecuentes</h2>
						</div>
					</div>
					<div className='grid grid-cols-5 gap-6 md:gap-0 w-full md:w-2/4'>
						{faq.map((q, index) => {
							return (
								<button key={`item_faq_button_${index}`} className={`pt-2 flex flex-col transition duration-300 justify-center items-center border-2 border-white  ${q.current ? 'border-b-primary opacity-100' : 'border-white opacity-60'}`}
									onClick={() => {
										setTopic(index);
										!q.current &&
											handleIconClick(index)
									}}>
									<div className='p-1'>
										{renderIcon(q.icon)}

									</div>
									<div className={`uppercase font-normal text-[10px] md:text-xs leading-none text-primary pb-2 ${q.current ? 'opacity-100' : 'text-primary opacity-60'}`}>
										{q.topic}
									</div>
								</button>
							)
						})}
					</div>
					<div className='w-full flex flex-wrap justify-center items-center mt-5'>
						<div className='content-faq grid gap-y-4 md:gap-y-0 grid-cols-1 md:grid-cols-2 items-start'>
							{faq[topic].content.map((q, index) => {
								return (
									<div key={`item_faq_content_${index}`} className="mb-4" style={{ flex: ' 0 0 calc(50% - 20px)' }}>
										<Accordion title={q.question} description={q.answer} />
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FAQ;