import React from 'react';
import './HiringOptions.css';
import IncomingCall from '../../assets/img/Incoming_call.png';
import Click from '../../assets/img/Click.png';

const HiringOptions = () => {
	return (
		<div className='hiringoptions text-center mt-8 md:mt-12'>
			<h2 className='text-primary font-semibold md:font-bold text-2xl md:text-3xl mb-4'>¿Qué opciones de contratación y pago tengo?</h2>
			<p className='text-bluish-gray font-regular text-base md:text-xl px-6 md:px-0'>Una vez que elijas tu plan ideal vas a poder elegir en el próximo paso, la forma de contratación.</p>
			<div className='grid grid-flow-col-dense md:flex md:flex-row md:justify-center my-10 md:my-14 md:gap-40 mx-10 md:mx-0'>
				<div className='flex flex-col justify-center items-center'>
					<img src={Click} alt='Click' className='item-img max-w-[80px] max-h-[69px] md:max-w-[120px] md:max-h-[109px]' />
					<p className='text-black font-semibold text-lg mt-6'>100% Online</p>
				</div>
				<div className='flex flex-col justify-center items-center'>
					<img src={IncomingCall} alt='IncomingCall' className='item-img max-w-[80px] max-h-[69px] md:max-w-[120px] md:max-h-[109px]' />
					<p className='text-black font-semibold text-lg mt-6'>Telefónicamente</p>
				</div>
			</div>
		</div>
	);
};

export default HiringOptions;