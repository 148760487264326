import React from 'react';
import './Button.css';
import PropTypes from 'prop-types';
import { Spinner } from '../../../assets/icons';

const sizes = {
	xs: 'px-4',
	sm: 'px-10',
	md: 'px-16',
	lg: 'px-22',
	xl: 'px-28',
	xxl: 'px-32',
	full: 'w-full',
	auto: 'w-auto'
}
const Button = ({ className = '', type = '', size = 'full', handleClick = () => { }, title = '', icon, loading = false, disabled = false, iconPosition = 'end' }) => {
	let sizeClasses = sizes[size];
	const textPrimary = '#FFFFFF';
	const textSecondary = '#7C1338';

	const primary = `text-white bg-primary border-primary`;
	const secondary = `text-primary border-primary`;

	let classType;
	let colorType;

	switch (type) {
		case 'primary':
			classType = primary;
			colorType = textPrimary;
			break;
		case 'secondary':
			classType = secondary;
			colorType = textSecondary;
			break;
		default:
			classType = '';
			colorType = textPrimary;
			break;
	}

	const buttonClass = className !== '' ? `${className} ${classType} ${sizeClasses} disabled:opacity-75 disabled:pointer-events-none` : `rounded-lg border-2 py-1 font-semibold text-lg ${sizeClasses} ${icon === undefined ? 'text-center' : 'flex flex-row justify-between px-4'} ${classType} disabled:opacity-75 disabled:pointer-events-none`;

	function ButtonComponent({ children }) {
		if (loading === true || disabled === true) {
			return (
				<button className={buttonClass} onClick={handleClick} disabled>
					<div className='flex justify-center'>
						{children}
						{loading === true ? <Spinner size={16} color={colorType} className={'ms-2 animate-spin'} /> : <></>}
					</div>
				</button>);
		} else {
			return <button className={buttonClass} onClick={handleClick}>{children}</button>;
		}
	}

	let childrenComponent = <></>;

	switch (iconPosition) {
		case 'top':
			childrenComponent =
				<div className='flex flex-col justify-center'>
					{icon === '' ? <></> : <React.Fragment>{icon}</React.Fragment>}
					<span className='whitespace-nowrap'>{title}</span>
				</div>
			break;
		default:
		case 'end':
			childrenComponent =
				<div className='flex flex-row justify-center'>
					<span className='whitespace-nowrap'>{title}</span>
					{icon === '' ? <></> : <React.Fragment>{icon}</React.Fragment>}
				</div>
			break;
		case 'bottom':
			childrenComponent =
				<div className='flex flex-col justify-center'>
					<span className='whitespace-nowrap'>{title}</span>
					{icon === '' ? <></> : <React.Fragment>{icon}</React.Fragment>}
				</div>
			break;
		case 'start':
			childrenComponent =
				<div className='flex flex-row justify-center'>
					{icon === '' ? <></> : <React.Fragment>{icon}</React.Fragment>}
					<span className='whitespace-nowrap'>{title}</span>
				</div>
			break;
	}

	return (
		<div className={`button_primary ${sizeClasses}`}>
			<ButtonComponent>
				{childrenComponent}
			</ButtonComponent>
		</div>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	size: PropTypes.string,
	handleClick: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	icon: PropTypes.element,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	iconPosition: PropTypes.oneOf(['top', 'end', 'bottom', 'start'])
};

export default Button;