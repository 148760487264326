import React from 'react';
import './DownloadComponent.css';
import PropTypes from 'prop-types';
import img_appStore from '../../assets/img/AppStore.png';
import img_googlePlay from '../../assets/img/GooglePlay.png';

const DownloadComponent = ({ text = false }) => {

	const classSizeImg = 'h-[38px]';

	if (text === false) {
		return (
			<>
				<p className='text-deep-burgundy font-bold text-lg text-center mb-4'>Descargar nuestra app</p>
				<div className="flex flex-row items-center justify-center gap-2 md:gap-4">
					<a href='https://play.google.com/store/apps/details?id=dibi.atmseguros' target='_blank' rel='noreferrer'>
						<img src={img_googlePlay} alt="img_googlePlay" className={classSizeImg} />
					</a>
					<a href='https://apps.apple.com/ar/app/atm-seguros/id1433020350?platform=iphone' target='_blank' rel='noreferrer'>
						<img src={img_appStore} alt="img_appStore" className={classSizeImg} />
					</a>
				</div>
			</>
		);
	}

	return (
		<div className='w-5/6 md:w-4/6 mx-auto mb-20 mt-16 md:my-12'>
			<h1 className='text-deep-burgundy font-bold text-2xl md:text-4xl text-center mb-4 md:mb-7'>Descargá la APP</h1>
			<p className='text-deep-burgundy font-semibold text-sm md:text-lg text-center mb-6 w-full md:w-5/12 mx-auto'>Podés descargarla tanto para Android como para Apple haciendo clic en los siguientes botones.</p>
			<div className="flex flex-row items-center justify-center gap-2 md:gap-4">
				<a href='https://play.google.com/store/apps/details?id=dibi.atmseguros' target='_blank' rel='noreferrer'>
					<img src={img_googlePlay} alt="img_googlePlay" className={classSizeImg} />
				</a>
				<a href='https://apps.apple.com/ar/app/atm-seguros/id1433020350?platform=iphone' target='_blank' rel='noreferrer'>
					<img src={img_appStore} alt="img_appStore" className={classSizeImg} />
				</a>
			</div>
		</div>
	);
};

DownloadComponent.propTypes = {
	text: PropTypes.bool
};

export default DownloadComponent;