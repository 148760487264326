const API_URL = process.env.REACT_APP_API_ECOMMERCE_URL;
const PROCESADOROK_URL = process.env.REACT_APP_URL_PROCESADOR_OK;
const EXPIRATION_STORAGE = process.env.REACT_APP_EXPIRATION_STORAGE;
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const RECAPTCHA_VISIBLE = process.env.REACT_APP_RECAPTCHA_VISIBLE;
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
const IV_KEY = process.env.REACT_APP_IV_KEY;
const TAG_MANAGER_KEY = process.env.REACT_APP_TAG_MANAGER;
const FACEBOOK_ID_KEY = process.env.REACT_APP_FACEBOOK_ID;
const ENV = process.env.REACT_APP_ENV;

export { API_URL, PROCESADOROK_URL, EXPIRATION_STORAGE, GOOGLE_API_KEY, RECAPTCHA_VISIBLE, ENCRYPTION_KEY, IV_KEY, TAG_MANAGER_KEY, FACEBOOK_ID_KEY, ENV };