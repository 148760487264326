import React, { useEffect } from 'react';
import './ProgressDot.css';
import PropTypes from 'prop-types';
import { Label } from '../basic/Label';
import { DotFilled, DotRounded, SideCar, SideMoto } from '../../assets/icons/lib';
import { useMyContext } from '../../context/global/Context';

const ProgressDot = ({ reference = 'progress_bar', activeStep = false, prev = false, label = '', handleClick = () => { }, className = '', inactiveColor = '#7C1338', iconSize = 78 }) => {
	const { currentBranch } = useMyContext();

	let labelComponent;

	const dotSize = 24;
	const carSize = [iconSize, 28];
	const motorbikeSize = [iconSize, 48];

	useEffect(() => {
		const aHorizontalLineStart = document.querySelectorAll(`#${reference} .horizontal_line_start`);

		aHorizontalLineStart.forEach((element, key) => {
			if (element && key > 0) {
				let offsetLeft = element.nextElementSibling.getBoundingClientRect().left + window.scrollX;
				let offsetRight = element.getBoundingClientRect().left + window.scrollX;

				element.style.width = `${offsetLeft - offsetRight}px`;
			}
		});

		const aHorizontalLineEnd = document.querySelectorAll(`#${reference} .horizontal_line_end`);

		aHorizontalLineEnd.forEach((element, key) => {
			if (element && key < (aHorizontalLineEnd.length - 1)) {
				let offsetRight = element.previousElementSibling.getBoundingClientRect().right + window.scrollX;
				let offsetLeft = element.getBoundingClientRect().right + window.scrollX;

				element.style.width = `${offsetLeft - offsetRight}px`;
			}
		});
	}, [activeStep]);

	if (typeof label === 'string') {
		const fontColor = activeStep === true || prev === true ? 'text-maroon' : 'text-shiny-pink';

		labelComponent =
			<React.Fragment>
				<Label className={`flex items-center ${fontColor} mt-4`} title={label} />
			</React.Fragment>;
	} else if (React.isValidElement(label)) {
		labelComponent = <React.Fragment>{label}</React.Fragment>
	} else {
		labelComponent = <></>;
	}

	let icon;

	switch (parseInt(currentBranch)) {
		case 3:
			icon = <SideCar size={carSize} color='#7C1338' />;
			break;
		case 4:
			icon = <SideMoto size={motorbikeSize} color='#7C1338' />;
			break;
		default:
			icon = <></>;
			break;
	}

	const activeIcon =
		<div className={`icon_container_progress_dot z-10 bg-white ${parseInt(currentBranch) === 4 && !activeStep ? 'mt-4' : ''}`} onClick={handleClick}>
			{
				activeStep === true ?
					icon
					:
					(prev === true ?
						<DotFilled size={dotSize} color={inactiveColor} />
						:
						<DotRounded size={dotSize} color={'#DE6180'} />
					)
			}
		</div>;

	const activeBgStart = prev === true ? 'bg-primary' : (activeStep === true ? 'bg-primary' : 'bg-shiny-pink');
	const activeBgEnd = prev === true ? 'bg-primary' : 'bg-shiny-pink';

	return (
		<div className={`progressdot flex ${activeStep === true ? 'active' : ''}`}>
			<div className={`flex flex-col ${className}`}>
				<div className="flex flex-col items-center">
					<div className={`horizontal_line_start ${activeBgStart} absolute left-0 ${parseInt(currentBranch) === 3 ? 'mt-2' : 'mt-6'} h-1 z-0 transition duration-500 ease-in-out`}></div>
					{activeIcon}
					<div className={`horizontal_line_end ${activeBgEnd} absolute right-0 ${parseInt(currentBranch) === 3 ? 'mt-2' : 'mt-6'} h-1 z-0 transition duration-500 ease-in-out`}></div>
				</div>
				<div className="flex flex-col items-center">
					{labelComponent}
				</div>
			</div>
		</div>
	);
};

ProgressDot.propTypes = {
	activeStep: PropTypes.bool,
	prev: PropTypes.bool,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	handleClick: PropTypes.func,
	className: PropTypes.string,
	reference: PropTypes.string,
	inactiveColor: PropTypes.string,
	iconSize: PropTypes.number
};

export default ProgressDot;