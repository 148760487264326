const formatNumber = (number, locale, style, currency) => {
    return number.toLocaleString(locale, {
        style: style,
        currency: currency
    });
}

const fillWithZeros = (number, qZero = 1, padding = 'right') => {
    let auxString = `${number}`;

    let zeros = '';
    for (let i = 0; i < qZero; i++) {
        switch (padding) {
            case 'right':
                auxString += '0';
                break;
            case 'left':
                zeros += '0';
                break;
            default:
                break;
        }
    }

    if (zeros !== '') {
        zeros += auxString;
        auxString = zeros;
    }

    return auxString;
}

const resetTime = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export { formatNumber, fillWithZeros, resetTime };