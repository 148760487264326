import { useEffect, useState } from 'react';
import { getCoverageLegends } from '../services';
import { isEmpty } from '../utils/validation';

const initial = [];

export default function useFetchCoverageLegends(branchId, target) {
    const [loadingCoverageLegends, setLoadingCoverageLegends] = useState(true);
    const [coverageLegends, setCoverageLegends] = useState(initial);

    useEffect(() => {
        if (!isEmpty(branchId)) {
            (async () => {
                setLoadingCoverageLegends(true);
                const response = await getCoverageLegends(branchId, target);

                if (response.body)
                    setCoverageLegends(response.body);

                setLoadingCoverageLegends(false);
            })();
        }
    }, [branchId, target]);

    return { coverageLegends, loadingCoverageLegends };
}