import React from 'react';

// TODO: revisar el secondaryColor
export default function GenIcon(data) {
    return function IconComponent(props) {
        var { size, color, opacity, secondary, className } = props;

        var width, height;

        if (Array.isArray(size)) {
            width = size[0];
            height = size[1];
        } else {
            width = size;
            height = size;
        }

        if (secondary === undefined) secondary = color;

        const replaceColor = (jsonObject, newColor) => {
            for (const key in jsonObject) {
                if (key === 'stroke' || key === 'fill') {
                    jsonObject[key] = newColor;
                }
            }
        };

        const replaceSecondaryColor = (jsonObject, newColor) => {
            for (const key in jsonObject) {
                if (key === 'stroke' || key === 'fill') {
                    jsonObject[key] = newColor;
                }
            }
        };

        const replaceWidth = (jsonObject, newWidth) => {
            for (const key in jsonObject) {
                if (typeof jsonObject[key] === 'string' && jsonObject[key].includes('currentWidth')) {
                    jsonObject[key] = jsonObject[key].replace(/currentWidth/g, newWidth);
                }
            }
        }

        const replaceHeight = (jsonObject, newWidth) => {
            for (const key in jsonObject) {
                if (typeof jsonObject[key] === 'string' && jsonObject[key].includes('currentHeight')) {
                    jsonObject[key] = jsonObject[key].replace(/currentHeight/g, newWidth);
                }
            }
        }

        const createComponent = (component, index) => {
            const { tag, attr, child } = component;

            if (!tag) return null;

            const Component = tag;

            replaceColor(attr, color);
            replaceSecondaryColor(attr, secondary);
            replaceWidth(attr, width);
            replaceHeight(attr, height);

            return (
                <Component key={`component_${index}`} {...attr}>
                    {child && child.map((childElement, idx) => createComponent(childElement, idx))}
                </Component>
            );
        }

        return (
            <div className='flex align-middle items-center icon_component justify-center'>
                <div className={className}>
                    <data.tag
                        {...data.attr}
                        width={width}
                        // height={height}
                        style={{ opacity: opacity }}
                    >
                        {data.child.map((child, idx) => createComponent(child, idx))}
                    </data.tag>
                </div>
            </div>
        );
    };
}