import { useState } from 'react';
import { getCitiesByPostalCode } from '../services';
import { defaultCities } from '../config';

const initial = defaultCities;

export default function useFetchCities() {
    const [cities, setCities] = useState(initial);
    const [loadingCities, setLoadingCities] = useState(false);

    const fetchCities = async (postalCode) => {
        setLoadingCities(true);

        if (postalCode.length >= 4) {
            const response = await getCitiesByPostalCode(postalCode);

            if (response.error === true) {
                setCities(initial);
                setLoadingCities(false);
                return { error: true, msg: response.msg };
            }

            if (response.body.length > 0) {
                const citiesResponse = response.body.map(function (v, i) {
                    return { value: v.id, title: v.description };
                });

                setCities([...initial, ...citiesResponse]);
            } else {
                setCities(initial);
            }
        } else {
            setCities(initial);
        }

        setLoadingCities(false);
        return { error: false, msg: '' };
    }

    const emptyCities = () => {
        setCities(initial);
    }

    return { cities, fetchCities, emptyCities, loadingCities };
}