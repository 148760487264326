import React from 'react';
import { Footer, Navbar } from '../../components';
import { Outlet } from 'react-router';

export default function Template() {
    return (
        <div id='template_root'>
            <Navbar />
            <Outlet />
            <Footer />
        </div>
    );
}