import { ENCRYPTION_KEY, IV_KEY } from '../config/constants';

var CryptoJS = require('crypto-js');

const encryptionKeyBase64 = ENCRYPTION_KEY;
const ivBase64 = IV_KEY;

const encryptionKey = CryptoJS.enc.Base64.parse(encryptionKeyBase64);
const iv = CryptoJS.enc.Base64.parse(ivBase64);

export const crypt = (text) => {
    const resultEncrypt = CryptoJS.AES.encrypt(text, encryptionKey, { iv: iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });

    return resultEncrypt.toString(CryptoJS.format.Base64);
}