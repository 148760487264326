import { useState } from "react";
import { defaultBodyCbuForm } from "../config";

const initial = defaultBodyCbuForm;

export default function useCbuData() {
    const [cbuForm, setCbuForm] = useState(initial);

    const modifyCbuForm = (body) => {
        setCbuForm({ ...cbuForm, ...body });
    }

    return { cbuForm, modifyCbuForm };
}
