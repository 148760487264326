import React from 'react';
import './ProgressBar.css';
import PropTypes from 'prop-types';
import { useMyContext } from '../../context/global/Context';

const ProgressBar = ({ id = 'progress_bar', children }) => {
	const { currentBranch } = useMyContext();

	const childrenArray = React.Children.map(children, (child, index) => {
		const borderColor = child.props.prev === true ? 'border-maroon' : 'border-shiny-pink';

		return (
			<React.Fragment>
				<div className="progressbar-item flex items-top relative">
					{React.cloneElement(child)}
				</div>
				<div className={`horizontal_line flex-auto border-t-4 transition duration-500 ease-in-out ${borderColor} ${parseInt(currentBranch) === 3 ? 'mt-2' : 'mt-6'}`}></div>
			</React.Fragment>
		);
	});

	return (
		<>
			<div id={id} className="progressbar flex items-top">
				{childrenArray}
			</div>
		</>
	);
};


ProgressBar.propTypes = {
	id: PropTypes.string
};

export default ProgressBar;