import React from 'react';
import './TermsAndConditions.css';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
	return (
		<div className='termsandconditions text-black font-normal text-xs md:text-sm text-justify space-y-4'>
			<h2 className='text-primary text-center text-lg md:text-xl font-bold md:mb-6'>Términos y condiciones de Uso del Sitio Web ATM y sus Servicios</h2>
			<ol className='list-decimal list-outside space-y-4'>
				<li><span className='font-bold'>Del Sitio:</span> El presente sitio web <Link to={'https://www.atmseguros.com.ar/'} className='text-primary' target='_blank'>https://www.atmseguros.com.ar/</Link> (en adelante, el “Sitio”) es administrado y de propiedad de ATM COMPAÑIA DE SEGUROS S.A., CUIT: 30-69940815-4, con domicilio legal en Florida 833 piso 2 oficina 204, Capital Federal,  (en adelante, “ATM”). Los presentes términos y condiciones regulan el uso del Sitio y establecen el alcance de los servicios ofrecidos en él. Asimismo, regirán los derechos y obligaciones entre los usuarios y ATM.</li>
				<li><span className='font-bold'>ACLARACIÓN:</span> antes de navegar y/o utilizar el sitio y/o los servicios prestados a través del mismo, por favor lea atentamente las presentes condiciones de uso y las políticas de privacidad de ATM. Para utilizar los servicios, el usuario deberá aceptar las condiciones de uso del sitio y condiciones de la política de privacidad de ATM; si el usuario no aceptase, no debe navegar ni utilizar el sitio y/o sus servicios. El usuario deberá ingresar a la sección del sitio denominada: “Términos y Condiciones”. Es necesario contar con conexión a internet. La utilización del servicio de internet puede generar gastos que deberán ser contratados por el usuario.</li>
				<li><span className='font-bold'>Naturaleza del Servicio y destino del Sitio:</span> El Sitio fue diseñado, editado y registrado en la República Argentina, y su contenido es susceptible de ser utilizado por el usuario argentino y/o nacionalizado con domicilio comprobable en la República Argentina, solo para rodados con dominio emitido por la República Argentina.</li>
				<li><span className='font-bold'>Navegación del Sitio y Cotización:</span> Para navegar en el Sitio y solicitar cotización para la cobertura de su auto y contratar un Seguro, no es necesario crear un usuario ni contraseña (esto sólo será requerido al momento de la contratación del Seguro ATM y sus Servicios). A los fines de solicitar una cotización, a continuación, se detallarán los requisitos y datos personales que serán requeridos:</li>
				<li>
					<span className='font-bold'>Cotización:</span>
					<ol className='list-decimal ps-10 md:ps-14 list-outside' start={'1'}>
						<li><span className='italic'>para cotizar sin informar datos del dominio:</span> (i) informar datos para identificar el auto: marca, modelo, año; (ii) informar si el auto tiene GNC; (iii) informar datos personales: fecha de nacimiento, barrio de residencia y código postal; (iv) seleccionar tipo de plan: una vez ingresados los datos indicados en el punto (i) y (ii), se le informará al Usuario opciones de planes con detalle de coberturas, límites y costos mensuales. El Usuario deberá elegir uno de esos planes de acuerdo a sus intereses; (v) Informar correo electrónico del Usuario, confirmación de correo electrónico, número de celular a fin de obtener validaciones, número de patente, número de motor (se puede encontrar en la cédula verde), número de chasis (se puede encontrar en la cédula verde) y uso del automóvil; (vi) Informar datos para el pago, (nombre y apellido, Número de DNI); domicilio de facturación: calle/ altura (piso y dpto), código postal y barrio.</li>
						<li><span className='italic'>para cotizar informando datos del dominio:</span> (i) Informar datos del auto, a saber: número de patente/dominio; identificación del auto: marca, modelo, año; (ii) Informar datos personales: fecha de nacimiento, barrio y/o código postal; (iii) Seleccionar tipo de plan: una vez ingresados los datos indicados en el punto (i) y (ii), se le informarán al Usuario planes disponibles y detalle de coberturas, límites y costos mensuales. El Usuario deberá elegir uno de esos planes de acuerdo a sus intereses; (iv) Informar correo electrónico del Usuario, confirmación de correo electrónico, número de celular a fin de obtener validaciones, número de motor (se puede encontrar en la cédula verde), número de chasis (se puede encontrar en la cédula verde) y uso del automóvil; (v) datos para el pago: nombre y apellido, Número de DNI; domicilio de facturación: calle y altura (piso y dpto.), código postal/barrio.</li>
					</ol>
				</li>
				<li><span className='font-bold'>Contratación del Seguro ATM:</span> No es necesaria la creación de cuenta y/o contraseña para contratar y/o cotizar con ATM. A los fines de contratar el Seguro ATM y sus Servicios (una vez finalizado el proceso de cotización), se le solicitará al usuario un correo electrónico con el fin de verificar su identidad y validar los datos ingresados a la plataforma de ATM. Esto ocurrirá con posterioridad a la contratación, al momento en que los Usuarios procedan a abonar el Seguro ATM y/o los Servicios contratados. Luego de haber contratado el Seguro ATM y Servicios, el Usuario podrá acceder a la póliza y los demás documentos en la siguiente pantalla del navegador y además los recibirá vía mail al correo electrónico que indicó durante la contratación. Podrá descargar de Google Play y App Store y de manera totalmente gratuita, una aplicación para dispositivos móviles a los fines de utilizar y gestionar el Seguro y demás Servicios (en adelante la “App ATM”).</li>
				<li>
					<span className='font-bold'>Condición para la validez del contrato de Seguro. Inspección Previa:</span> Condición para la validez del contrato de Seguro. Inspección Previa: Con el objetivo de cumplir con la Inspección Previa, se le informará al Usuario que a partir de la fecha de emisión de la póliza, cuenta con un plazo predeterminado para remitir a través de la plataforma de inspecciones ciertas fotos del vehículo asegurado y demás documentos requeridos en forma clara, detallada y precisa, pudiendo incluso contactar en caso de dudas o consultas con el servicio de atención al asegurado, dónde se le brindará asesoramiento ante una necesidad. Si transcurrido el plazo predeterminado, las fotos no fueran enviadas, el contrato de seguro no será válido, y se procederá anular la póliza, sin que el usuario tenga derecho a efectuar reclamo alguno. La inspección previa será realizada dentro de las 72 horas hábiles contadas desde la recepción adecuada por medio de la plataforma de inspecciones de las fotos del vehículo asegurado y demás documentos requeridos. En caso que no sea superada la inspección previa y corresponda el rechazo, el contrato será anulado desde su inicio de vigencia, realizando la devolución del premio cobrado al medio de pago correspondiente declarado. En cualquiera de estos casos no podrá solicitar asistencia mecánica ni tendrá cobertura ante un siniestro.
					<ol className='list-decimal ps-10 md:ps-14 list-outside' start={'0'}>
						<li><span className='font-bold'>Autos usados:</span> En el caso de los autos usados (con más de 3 meses de antigüedad desde su patentamiento), las fotos requeridas en la inspección son: (i) Foto frontal; (ii) Foto trasera; (iii) Foto lateral izquierdo; (iv) Foto lateral derecho; (v) Foto del cuentakilómetros; (vi) Foto rueda de auxilio: esta foto es opcional. Si el Usuario no la envía, no tendrá la cobertura correspondiente a su reposición; (vii) Foto del frente de la cédula verde; (viii) Foto del reverso de la cédula verde. En caso de que el vehículo no esté a nombre del asegurado se le podrá requerir adicionalmente el título de dominio, la autorización del titular, el documento nacional de identidad o cualquier otro instrumento que acredite los datos informados por el cliente, según corresponda.</li>
						<li><span className='font-bold'>Autos 0 KM:</span> En el caso de los autos 0 KM (con menos de 3 meses de antigüedad desde su patentamiento), las fotos requeridas en la inspección son: (i) Foto del certificado de no rodamiento; (ii) Foto del frente de la cédula verde; (iii) Foto del reverso de la cédula verde. En caso de que el vehículo no esté a nombre del asegurado se le podrá requerir adicionalmente el título de dominio, la autorización del titular, el documento nacional de identidad o cualquier otro instrumento que acredite los datos informados por el cliente, según corresponda.</li>
					</ol>
				</li>
				<li><span className='font-bold'>Formas de pago de la contratación del Seguro ATM:</span> Luego de realizar la cotización de alguna de las maneras explicadas en el punto 5, el Usuario deberá abonar el Seguro ATM y los Servicios contratados de acuerdo a la cobertura elegida. El pago se instrumenta mediante las tarjetas de crédito habilitadas por ATM previamente.</li>
				<li><span className='font-bold'>Composición de la cuota:</span> El detalle del pago mencionado en el punto 7) o “la cuota” que responderá al plan de seguro elegido por el usuario, se encuentra visible e inserta en la póliza que se emita a tal efecto. Los precios finales se componen de los siguientes elementos: 1) La Prima tarifa; 2) El recargo financiero: porcentaje que se aplica sobre la prima para financiar el pago de la póliza de seguros; 3) Tasa impuesta por la Superintendencia de Seguros de la Nación (SSN); 4) Impuesto de Sellos; 5) Impuesto al Valor Agregado (IVA); 6) Aporte Bomberil; 7) Contribución Agencia Nacional de Seguridad Vial.</li>
			</ol>
			<p>IMPORTANTE: Para un mayor servicio los planes incluyen una cobertura de VIDA individual  destinada a cubrir el fallecimiento por enfermedad o accidente del titular de la póliza contratada  y un seguro automotor / motovehículo en las condiciones aquí especificadas. El costo informado corresponde a ambas coberturas. Para las personas físicas con condición de consumidor final. Desde 18 hasta 65 años. Una única póliza de vida con su seguro de moto o auto (el primero en contratarse).</p>
			<p><span className='font-bold'>Modificaciones de los Condiciones de Uso del Sitio:</span> ATM se reserva el derecho de revisar, complementar, actualizar y/ o modificar las presentes Condiciones de Uso del Sitio. En caso de que el Usuario no formule observaciones o comentarios a las modificaciones informadas en el Sitio dentro de los 30 días corridos de informadas, se considerará que el Usuario acepta tácitamente las mismas.</p>
			<p><span className='font-bold'>Indemnidad:</span> Al navegar y/o aceptar estas Condiciones de Uso del Sitio y Servicio, el Usuario conoce y acepta que será responsable y deberá indemnizar y mantener indemne a ATM, de todo perjuicio económico (incluyendo sin limitación, cualquier reclamo legal o de otra naturaleza, denuncia, penalidad, intereses, gastos, multas, cargos, costos y costas judiciales y honorarios de abogados), que directa o indirectamente pudiera sufrir ATM como consecuencia del incumplimiento por parte del Usuario de estas Condiciones de Uso del Sitio y Servicio.</p>
			<p><span className='font-bold'>LEY 24.240 (texto conforme ley 26.361) Defensa del Consumidor. ARTÍCULO 34. — Revocación de aceptación:</span> En los casos previstos en los artículos 32 y 33 de la presente Ley, el Usuario (consumidor) tiene derecho a revocar la aceptación de su contratación durante el plazo de DIEZ (10) días corridos contados a partir de la fecha en que se celebre el contrato, sin responsabilidad alguna para el consumidor. Esta facultad no puede ser dispensada ni renunciada. Tal información debe ser incluida en forma clara y - notoria. El Usuario (consumidor) debe poner el bien a disposición del vendedor y los gastos de devolución son por cuenta de este último. A través del presente, ATM cumple con su deber de informar por escrito al Usuario (consumidor) de esta facultad de revocación así como también lo informará en todo documento que con motivo de venta o prestación del servicio le sea presentado al Usuario (consumidor).</p>
			<p><span className='font-bold'>SOLICITUD DE BAJA ONLINE (conforme artículo 10 ter de la ley 24.240 texto conforme ley 26.361). Modos de Rescisión:</span> Cuando la contratación de un servicio, incluidos los servicios públicos domiciliarios, haya sido realizada en forma telefónica, electrónica o similar, podrá ser rescindida a elección del consumidor o usuario mediante el mismo medio utilizado en la contratación. La empresa receptora del pedido de rescisión del servicio deberá enviar sin cargo al domicilio del consumidor o usuario una constancia fehaciente dentro de las SETENTA Y DOS (72) horas posteriores a la recepción del pedido de rescisión. Esta disposición debe ser publicada en la factura o documento equivalente que la empresa enviará regularmente al domicilio del consumidor o usuario. El Usuario podrá solicitar la baja enviando un mail a hola@ATM.com, indicando nombre, apellido, DNI y un teléfono de contacto.</p>
			<p><span className='font-bold'>Rescisión del contrato:</span> Con arreglo a las disposiciones que emergen de la Ley de Seguros N° 17.418, en particular a lo contenido en el Art. 18 segundo párrafo, y el punto 25.4.1 inciso e) del Reglamento General de la Actividad Aseguradora, se establece lo siguiente:</p>
			<p>Las partes tendrán derecho a rescindir el contrato que los vincula sin expresión de causa. En el caso de que dicha opción sea ejercida por el Asegurado, sólo podrá llevarse a cabo si existe un pedido expreso al efecto; tendrá derecho a percibir la prima devengada por el tiempo transcurrido. La aseguradora deberá poner a disposición el comprobante de rescisión respectivo.</p>
			<p>Si la rescisión es promovida por la aseguradora, deberá remitir una nota por medios electrónicos que hayan sido denunciados por el asegurado como canal común y habitual de comunicación, con un preaviso no menor a quince días. La aseguradora deberá poner a disposición el comprobante de rescisión respectivo y reembolsar la prima proporcional por el plazo no corrido.</p>
			<p>Requisitos y trámite:</p>
			<p>De acuerdo con la Ley de Defensa del Consumidor y la normativa vigente en materia de seguros, el tomador puede solicitar la baja y/o rescisión por los medios previstos en la póliza, o por el mismo canal por el que contrató el seguro. En tal sentido, cuando la contratación haya sido realizada en forma telefónica, correo electrónico o chat exclusivo de la Web de ATM, el consumidor podrá igualmente impulsar la rescisión por el mismo medio utilizado en la contratación. Para hacer lugar al pedido de baja, deberá ser expreso y sólo podrá realizarlo el tomador de la póliza. La aseguradora recibirá el pedido y se está facultada a pedirle al solicitante datos personales para verificar identidad, como ser: nombre completo, D.N.I., chapa patente del vehículo, número de póliza, si tuvo algún siniestro a la fecha. Luego de corroborar la información, hará lugar al pedido y remitirá el certificado de rescisión respectivo.</p>
			<ol className='space-y-4'>
				<li><span className='font-bold'>Sitios de terceros:</span> ATM no asume responsabilidad con respecto a los sitios de terceros que, por cualquier razón, estén enlazados con el Sitio. El acceso a dichos sitios que pudiere realizar se realiza bajo la exclusiva responsabilidad del Usuario y con sujeción a los términos y condiciones establecidos para dichos sitios. El Usuario acepta y reconoce que ATM no otorga garantías de ningún tipo respecto a la veracidad, contenido y/o seguridad sobre sitios de terceros. El acceso a sitios de terceros desde el Sitio no significa que tales sitios de terceros sean de propiedad de ATM como tampoco que ATM tenga algún tipo de control y/o responsabilidad respecto de los mismos. Por tal razón, cualquier perjuicio que sufra es ajeno a la responsabilidad de ATM.</li>
				<li><span className='font-bold'>DATOS PERSONALES. POLÍTICA DE PRIVACIDAD. GRUPO ECONÓMICO.</span> El usuario entiende y acepta que sus datos personales podrán ser procesados por ATM COMPAÑÍA DE SEGUROS S.A. y las empresas relacionadas, controladas, que controlen, o que estén sujeta a control común con ATM COMPAÑÍA DE SEGUROS S.A., con fines de evaluación crediticia, análisis de riesgo, operativos, de almacenamiento de datos y/o para realizar acciones de publicidad y de marketing. Usted entiende y consiente que sus datos personales podrán ser transferidos a terceros en virtud del cumplimiento de un contrato de prestación de servicios suscripto por la ATM  COMPAÑÍA DE SEGUROS S.A., en cuyo caso ATM COMPAÑÍA DE SEGUROS S.A. adoptará las medidas necesarias para garantizar una adecuada protección. El otorgamiento de sus datos personales es facultativo y brindarlos en forma incorrecta no permitirá que ATM  COMPAÑÍA DE SEGUROS S.A. pueda otorgarle el servicio contratado, ni contactarlo con fines publicitarios y de marketing. Ud., en su carácter de titular de los datos personales, tiene la facultad de ejercer el derecho de acceder o modificar los mismos en forma gratuita, mediante una carta firmada entregada en el Punto de Venta en donde suministró sus datos. LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.</li>
				<li><span className='font-bold'>Derecho aplicable y jurisdicción:</span> ATM manifiesta que ante controversias que se susciten de acuerdo a los servicios y productos que preste, se someterá a las leyes aplicables dentro del territorio de la República Argentina. A tales efectos, acepta que por cualquier reclamo conocerá el juez o tribunal ordinario con jurisdicción y competencia del domicilio del Usuario.</li>
			</ol>
			<p>Como en todos los seguros existen riesgos no cubiertos. Ante dudas o pedido de asesoramiento comunicarse directamente con la compañía aseguradora al 0810-345-0492, o a través de la web <Link to={'https://www.atmseguros.com.ar'} className='text-primary' target='_blank'>https://www.atmseguros.com.ar</Link> ATM COMPAÑÍA DE SEGUROS S.A CUIT: 30-69940815-4 Florida 833 piso 2 oficina 204 C.A.B.A. N° de Inscripción en SSN 0812.</p>
			<p>Atención al asegurado 0810-345-0492. Organismo de control <Link to={'https://www.argentina.gob.ar/ssn'} className='text-primary' target='_blank'>www.argentina.gob.ar/ssn</Link></p>
		</div>
	);
};

export default TermsAndConditions;