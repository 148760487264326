import { useState } from 'react';
import { getModelsByBrand } from '../services';
import { defaultValueModels } from '../config';

const initial = defaultValueModels;

export default function useFetchModels() {
    const [models, setModels] = useState(initial);
    const [loadingModels, setLoadingModels] = useState(false);

    const getModels = async (brandId, year) => {
        setLoadingModels(true);

        await getModelsByBrand(brandId, year)
            .then(response => {
                const models = response.map(function (v, i) {
                    return { value: v.id, title: v.description };
                });

                setModels([...initial, ...models]);
            });

        setLoadingModels(false);
    }

    const emptyModels = () => {
        setModels(initial);
    }

    return { models, getModels, emptyModels, loadingModels };
}