import React, { useEffect, useState } from 'react';
import './Step2.css';
import { ArrowLeft, ArrowUp } from '../../../assets/icons';
import { useMyContext } from '../../../context/global/Context';
import { useFetchCoverages, useFetchGoogleAnalytics } from '../../../hooks';
import HiringOptions from '../../HiringOptions/HiringOptions';
import Loading from '../../Loading/Loading';
import QuotationOptions from '../../QuotationOptions/QuotationOptions';
import { Button } from '../../basic';
import { scrollToInfo } from '../../../utils/functions';

const Step2 = () => {
	const { functions: { handleStepQuotation, handlePotencialPolicy }, potencialPolicy, vehicleData } = useMyContext();

	const { coverages, loadingCoverages } = useFetchCoverages();
	const { logViewItemList } = useFetchGoogleAnalytics(2);

	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (loadingCoverages === false) {
			logViewItemList();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingCoverages]);

	useEffect(() => {
		setDisabled(vehicleData.Vigency);
	}, [vehicleData]);

	const handleStepView = (step) => {
		handleStepQuotation(step);
	}

	const handleSelectedCoverage = (id) => {
		handlePotencialPolicy(coverages.find(e => e.coverageId === id));
	}

	return (
		<div className='step2'>
			{
				loadingCoverages ?
					<Loading />
					:
					<QuotationOptions coverages={coverages} selectedCoverage={potencialPolicy} setSelectedCoverage={handleSelectedCoverage} />
			}
			{
				disabled === false ?
					<HiringOptions />
					:
					<></>
			}
			<div className="flex flex-col-reverse md:flex-row mt-4 pt-1 w-full md:w-3/4 mx-auto justify-center px-6 md:px-0">
				<div className="flex w-full md:w-auto justify-center md:justify-start">
					<Button title="volver al paso anterior" className={`border-0 py-2 font-semibold text-base uppercase bg-white text-light-grey-disabled flex flex-row items-center ${disabled === false ? 'md:me-10' : ''}`} icon={<ArrowLeft size={23} color={'#A1AEB7'} className={'ms-4'} />} size={'auto'} handleClick={() => { handleStepView(1) }} disabled={false} />
				</div>
				{
					disabled === false ?
						<Button title="elegí tu plan" className={'uppercase bg-deep-pink text-white rounded-md py-2 mb-6 md:mb-0 hover:bg-opacity-85'} size={'full'} handleClick={() => { scrollToInfo() }} icon={<ArrowUp size={16} color='white' className='me-4' />} iconPosition={'start'} />
						:
						<></>
				}
			</div>
		</div>
	);
};

export default Step2;