import React, { useEffect, useState } from 'react';
import { ArrowLeft, Error } from '../../assets/icons/lib';
import './ErrorComponent.css';
import { Button } from '../basic';
import { useMyContext } from '../../context/global/Context';
import { useFetchGoogleAnalytics } from '../../hooks';

const ErrorComponent = () => {
	const { functions: { clearQuote }, currentBranch } = useMyContext();

	const { logPageView } = useFetchGoogleAnalytics();

	const [goHome, setGoHome] = useState(false);

	useEffect(() => {
		logPageView(`/error-cotizar-${currentBranch}`, 'Error');
	}, []);

	const handleGoHome = () => {
		clearQuote();
		setGoHome(true);
	}

	useEffect(() => {
		if (goHome === true) {
			window.open('/', '_self');
		}
	}, [goHome]);

	return (
		<div className='errorcomponent w-full my-24 md:my-36'>
			<div className="flex flex-row justify-center gap-x-16">
				<div className="flex flex-col self-center">
					<h1 className="text-center text-deep-magenta font-bold text-4xl flex flex-row items-center justify-center">
						<Error size={45} color='#B41847' className='me-3' />
						<span>Lo sentimos</span>
					</h1>
					<p className="text-center text-black font-bold text-xl mt-8 mb-16 px-8 md:px-0">En este momento no se pudo procesar tu solicitud. Por favor, volvé a intentarlo en unos minutos.</p>
					<div className="flex w-full justify-center">
						<Button title="volver" className={'border-0 py-2 font-semibold text-base uppercase bg-white text-light-grey-disabled flex flex-row items-center'} icon={<ArrowLeft size={23} color={'#A1AEB7'} className={'ms-4'} />} size={'auto'} handleClick={handleGoHome} disabled={false} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ErrorComponent;