import React, { useEffect } from 'react';
import gif from '../../assets/img/maintenance_mode.jpg';

export default function Maintenance() {

    useEffect(() => {
        const height = document.documentElement.scrollHeight;

        document.getElementById('main_container').style = `height: ${height}px`;
    }, []);

    return (
        <div id="main_container" className="flex justify-center">
            <div className="grid grid-cols-2 items-center max-w-screen-lg h-full gap-6">
                <div className="flex flex-col ps-4">
                    <h1 className="text-start text-primary font-bold text-2xl uppercase">NOS ENCONTRAMOS TRABAJANDO</h1>
                    <p className='text-start text-black font-normal text-lg'>Por favor volve a intentar en unos instantes</p>
                </div>
                <div className="flex pe-4">
                    <img id="main_img" src={gif} className='w-full' alt="maintenance_mode_gif" />
                </div>
            </div>
        </div>
    );
}