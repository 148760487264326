import React, { useEffect, useState, useRef } from "react";
import { ArrowLeft, Car, DoubleCheck, Motorbike, Star } from '../../assets/icons';
import { Button, Check, Input, Label, Select } from "../../components/basic";
import { About, Carousel, FAQ, Contact, Modal, ErrorModal } from "../../components";
import './index.css';
import { useMyContext } from "../../context/global/Context";
import { useLocation } from "react-router-dom";
import { useFetchBrands, useFetchCeroKm, useFetchGoogleAnalytics, useFetchModels, useGetSaleCenterSections } from "../../hooks";
import { isEmpty, onlyNumber, validateLicence, validateRequiredFields } from "../../utils/validation";
import { defaultVehicleValue } from "../../config";
import Loading from "../../components/Loading/Loading";
import { GOOGLE_API_KEY, RECAPTCHA_VISIBLE } from "../../config/constants";
import ReCAPTCHA from "react-google-recaptcha";


export default function Home() {
    const { functions: { validateYear, searchVehicleByLicense, clearQuote, getPdfDataDownload, handleCurrentBranch, handleManualQuotation, handleActiveProduct }, vehicleData, parameters, policy, documentDownloaded, currentBranch, quote, sumAsegHasta, activeProduct } = useMyContext();
    const { logPageView } = useFetchGoogleAnalytics();

    const location = useLocation();

    const [activeBranch, setActiveBranch] = useState(0);
    const { arraySections } = useGetSaleCenterSections();

    const [license, setLicense] = useState('');
    const [vehicleDataLoaded, setVehicleDataLoaded] = useState(false);
    const [licenseWindow, setLicenseWindow] = useState(true);
    const [handleShowModalQuotation, setHandleShowModalQuotation] = useState(false);
    const [loadingQuotation, setLoadingQuotation] = useState(false);

    const { brands, getBrands, loadingBrands } = useFetchBrands();
    const { models, getModels, emptyModels, loadingModels } = useFetchModels();

    const [year, setYear] = useState('');
    const { ceroKmDisabled } = useFetchCeroKm(year);

    const [brand, setBrand] = useState('');
    const [brandName, setBrandName] = useState('');
    const [model, setModel] = useState(0);
    const [modelName, setModelName] = useState('');

    const [modalType, setModalType] = useState('plain');
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [modalPolicyShow, setModalPolicyShow] = useState(false);
    const [modalVigencyShow, setModalVigencyShow] = useState(false);

    const [showErrorYear, setShowErrorYear] = useState(false);
    const [showErrorBrand, setShowErrorBrand] = useState(false);
    const [showErrorModel, setShowErrorModel] = useState(false);
    const [msgErrorYear, setMsgErrorYear] = useState('');

    const [ceroKm, setCeroKm] = useState(false);

    const activeBranchClass = 'border-b-4 border-maroon text-maroon';
    const inactiveBranchClass = 'border-b-4 border-white text-light-grey-disabled';
    const mainColor = '#7C1338';
    const disabledColor = '#979797';
    const golden = '#FFCB45';
    const sizeStar = 13;

    const coverageLegends = [{ legend: '20% de descuento por débito automático' }, { legend: '15% de descuento por contratación online' }];
    const loadingCoverageLegends = false;

    const recaptcha = useRef();
    const recaptchaVisible = parseInt(RECAPTCHA_VISIBLE) === 1;

    useEffect(() => {
        if (!isEmpty(arraySections) && !isEmpty(currentBranch)) {
            if (arraySections.length === 1) {
                setActiveBranch(arraySections[0].section);
            } else {
                setActiveBranch(parseInt(currentBranch));
            }
        }
    }, [arraySections, currentBranch]);

    useEffect(() => {
        logPageView('/home', 'Ecommerce ATM Seguros');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (policy)
            setModalPolicyShow(!documentDownloaded);
    }, [policy, documentDownloaded]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        if (!isEmpty(queryParams) && !isEmpty(arraySections)) {
            let b = null;

            for (let param of queryParams) {
                b = arraySections.find(e => e.uri === param[0]);
                break;
            }

            if (!isEmpty(b)) {
                handleBranch(b);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, arraySections, currentBranch]);

    useEffect(() => {
        if (vehicleData !== null) {
            setLicense(vehicleData.Patente);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleData]);

    useEffect(() => {
        setShowErrorYear(false);

        if (year !== '') {
            if (!validateYear(year)) {
                const minYear = parameters.find(item => item.tag === 'MIN_ANIO_VIGENTE').value;
                const maxYear = parameters.find(item => item.tag === 'MAX_ANIO_VIGENTE').value;

                setMsgErrorYear(`El año no es correcto, debe estar comprendido entre el ${minYear} y el ${maxYear}.`);
                setShowErrorYear(true);
            }
        }

        if (brand !== '' && year !== '') {
            handleChangeBrands();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, brand]);

    useEffect(() => {
        if (!isEmpty(activeBranch)) {
            getBrands(activeBranch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeBranch]);

    useEffect(() => {
        if (quote !== null) {
            setHandleShowModalQuotation(true);
        }
    }, [quote]);

    useEffect(() => {
        if (ceroKmDisabled === true) {
            setCeroKm(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ceroKmDisabled]);

    const handleClickCotizacion = async (withLicense = false) => {
        setShowErrorBrand(false);
        setShowErrorModel(false);
        setShowErrorYear(false);

        clearQuote();

        setLoadingQuotation(true);

        const resultValidation = validateRequiredFields('', 'start_quotation_form');

        let token = 'NA';

        if (withLicense === true) {
            if (resultValidation) {
                if (validateLicence(license, activeBranch)) {
                    if (recaptchaVisible === true) {
                        token = await recaptcha.current.executeAsync();
                        recaptcha.current.reset();
                    }
                    
                    const response = await searchVehicleByLicense(activeBranch, license, token);

                    if (response.error === true) {
                        setLoadingQuotation(false);

                        setModalTitle(isEmpty(response.title) ? '¡Ups! No hemos encontrado tu patente' : response.title);
                        setModalText(response.msg);
                        setModalType('error');
                        setModalShow(true);
                    } else {
                        setVehicleDataLoaded(true);
                        setLoadingQuotation(false);
                    }
                } else {
                    setLoadingQuotation(false);

                    setModalTitle('Atención');

                    let licenseExample;
                    switch (activeBranch) {
                        case 3:
                            licenseExample = 'ABC123 o AB123CD';
                            break;
                        case 4:
                            licenseExample = '123ABC o A123BCD';
                            break;
                        default:
                            licenseExample = '';
                            break;
                    }

                    setModalText(`Debes ingresar una patente válida (${licenseExample}).`);
                    setModalType('error');
                    setModalShow(true);
                }
            } else {
                setLoadingQuotation(false);
            }
        } else {
            if (resultValidation && validateYear(year)) {
                await handleManualQuotation({ ...defaultVehicleValue, ...{ Anio: parseInt(year), Rama: parseInt(activeBranch), MarcaCodigo: brand, ModeloCodigo: model, MarcaDescripcion: brandName, Descripcion: modelName, CeroKm: ceroKm } });
                setVehicleDataLoaded(true);
            } else {
                setLoadingQuotation(false);

                if (isEmpty(brand))
                    setShowErrorBrand(true);

                if (isEmpty(model))
                    setShowErrorModel(true);

                if (isEmpty(year) || !validateYear(year))
                    setShowErrorYear(true);
            }
        }
    }

    const handleCloseModal = () => {
        setHandleShowModalQuotation(false);
        clearQuote();
    }

    const handleQuotationView = (license) => {
        setLicenseWindow(license);

        if (license === false) {
            getBrands(activeBranch);
        }

        if (license === false) {
            setShowErrorBrand(false);
            setShowErrorModel(false);
            setShowErrorYear(false);
        }
    }

    const handleChangeBrands = () => {
        emptyModels();
        getModels(brand, year);
    }

    const handleChangeSearch = (type, value) => {
        switch (type) {
            case 'year':
                if (onlyNumber(value)) {
                    validateRequiredFields('input_year_quotation');
                    setYear(value);
                }
                break;
            case 'brand':
                setBrand(value);
                break;
            case 'model':
                setModel(value);
                break;
            default:
                break;
        }
    }

    const handleDownloadPolicy = async () => {
        await getPdfDataDownload()
            .then(() => {
                clearQuote();
                setModalPolicyShow(false);
            });
    }

    const handleCloseModalDownloadPolicy = () => {
        clearQuote();
        setModalPolicyShow(false);
    }

    const handleChangeLicense = (value) => {
        setLicense(value.toUpperCase());
    }

    const handleCheckLicense = () => {
        const checked = !ceroKm;

        if (checked === true) {
            setModalTitle('Información importante');
            setModalText(`Tenés que tener en cuenta que para algunas coberturas vamos a solicitarte el Certificado de No Rodamiento o la factura de compra.`);
            setModalType('info');
            setModalShow(true);
        }

        setCeroKm(checked);
    }

    const validateSumAssured = () => {
        if (parseFloat(vehicleData.Infoauto.sumAssured) > parseFloat(sumAsegHasta)) {
            setModalTitle('Lo sentimos');
            setModalText(`La suma asegurada de tu vehículo supera la pauta de suscripción de la compañía.`);
            setModalType('error');
            setModalShow(true);

            return false;
        } else {
            return true;
        }
    }

    let renderView = <></>;

    switch (licenseWindow) {
        case true:
            renderView =
                <>
                    {
                        loadingCoverageLegends === true ?
                            <Loading className={'hidden md:block md:pb-8 md:mb-1'} />
                            :
                            (
                                coverageLegends.length > 0 ?
                                    <div className="hidden md:flex md:flex-row md:justify-around mb-10">
                                        {
                                            coverageLegends.map((e, i) => {
                                                return (
                                                    <div key={`unique_key_div_coverageLegends_${i}`} className="flex flex-row items-center justify-center mb-6 md:mb-0">
                                                        <DoubleCheck size={16} color={'#DE6180'} />
                                                        <p className="ms-2 md:text-left text-center font-semibold text-xs">{e.legend}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <></>
                            )
                    }
                    <div className="flex flex-col">
                        <div id="start_quotation_form" className="flex flex-col mb-6">
                            <Input type="text" placeholder={'Ingresá la patente'} className={'font-bold px-4 py-3 w-full placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded-lg focus:border-shinyPink border-maroon rounded-lg border-2'} handleChange={(e) => { handleChangeLicense(e.target.value) }} value={license} maxLength={7} required />
                        </div>
                        <Button type={'primary'} title="cotizar" className={'rounded-lg border-2 py-2 font-semibold text-base uppercase mb-3 hover:bg-opacity-85 hover:border-opacity-5 hover:border-maroon'} size={'full'} handleClick={() => { handleClickCotizacion(true) }} loading={loadingQuotation} />
                        <Button type={'secondary'} title="cotizar sin patente" className={'rounded-lg border-2 py-2 font-semibold text-base uppercase bg-shiny-pink-lighter hover:bg-shiny-pink hover:bg-opacity-40 hover:text-primary'} size={'full'} handleClick={() => { handleQuotationView(false) }} />
                        {
                            recaptchaVisible === true ?
                                <div className="flex flex-row">
                                    <ReCAPTCHA ref={recaptcha} sitekey={GOOGLE_API_KEY} size='invisible' />
                                </div>
                                :
                                <></>
                        }
                    </div>
                </>
            break;
        case false:
            renderView =
                <>
                    {
                        coverageLegends.length > 0 ?
                            <div className="hidden md:flex md:flex-row md:justify-around mb-10">
                                {
                                    coverageLegends.map((e, i) => {
                                        return (
                                            <div key={`unique_key_div_coverageLegends_${i}`} className="flex flex-row items-center justify-center mb-6 md:mb-0">
                                                <DoubleCheck size={16} color={'#DE6180'} />
                                                <p className="ms-2 md:text-left text-center font-semibold text-xs">{e.legend}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <></>
                    }
                    <div id="start_quotation_form" className="flex flex-col justify-center items-center">
                        <div className={`flex flex-col w-full ${showErrorYear === true ? 'mb-2' : 'mb-8'}`}>
                            <div className={'grid grid-cols-2 gap-x-4 md:gap-x-6'}>
                                <div className="flex flex-col">
                                    <Input name="input_year_quotation" type="text" placeholder={'Ingresá el año'} className={`${showErrorYear === true ? 'mb-2' : ''} px-4 py-3 w-full placeholder:font-normal placeholder:text-black placeholder:text-base focus:border-maroon focus:border-b-shiny-pink focus:border-t-white focus:border-x-white border-b-shiny-pink border-2 border-t-white border-x-white`} handleChange={(e) => { handleChangeSearch('year', e.target.value) }} value={year} required />
                                    {
                                        showErrorYear === true && <span className="text-red-err font-semibold text-xs">{msgErrorYear}</span>
                                    }
                                </div>
                                <div className='flex flex-row items-start justify-center gap-x-2 text-nowrap mt-4'>
                                    <Label title={<div className="flex items-center"><Label title={'sin rodar'} className={`font-bold uppercase ${ceroKmDisabled === true ? 'text-grey' : 'text-primary'} text-xl mx-2 transition-all`} /></div>} className={'uppercase text-xs font-semibold text-shiny-pink'} />
                                    <Check checked={ceroKm} handleCheck={handleCheckLicense} disabled={ceroKmDisabled} />
                                </div>
                            </div>
                        </div>
                        <div className={`flex flex-col w-full ${showErrorBrand === true ? 'mb-4' : 'mb-10'}`}>
                            <Select list={brands} className={"data_search text-black text-base placeholder:font-normal border-t-white border-x-white border-2 border-b-shiny-pink rounded-none min-w-full w-full ps-4 pe-2 py-3 bg-white overflow-hidden"} onChange={(e) => { handleChangeSearch('brand', e.target.value); setBrandName(e.target.selectedOptions[0].innerText); }} required disabled={loadingBrands} />
                        </div>
                        <div className={`flex flex-col w-full ${showErrorModel === true ? 'mb-8' : 'mb-16'}`}>
                            <Select list={models} color={"#282828"} className={"data_search text-black text-base placeholder:font-normal border-t-white border-x-white border-2 border-b-shiny-pink rounded-none min-w-full w-full ps-4 pe-2 py-3 bg-white overflow-hidden"} onChange={(e) => { handleChangeSearch('model', e.target.value); setModelName(e.target.selectedOptions[0].innerText); }} required disabled={loadingModels} />
                        </div>
                        <Button type={'primary'} title="cotizar" className={'rounded-lg py-2 font-semibold text-base uppercase mb-3 mt-2 hover:bg-opacity-85 hover:border-opacity-5 hover:border-maroon'} size={'full'} handleClick={() => { handleClickCotizacion(false) }} loading={loadingQuotation} />
                        <button className="border-0 py-2 font-semibold text-base uppercase bg-white text-light-grey-disabled flex flex-row items-center" onClick={() => setLicenseWindow(true)}>volver al paso anterior <ArrowLeft size={24} opacity={1.0} color={'#979797'} /></button>
                    </div>
                </>
            break;
        default:
            break;
    }

    useEffect(() => {
        if (!isEmpty(vehicleData.Anio)) {
            if (vehicleDataLoaded === true) {
                const validatedSumAssured = validateSumAssured();

                setLoadingQuotation(false);

                if (validatedSumAssured === true) {
                    if (vehicleData.Vigency === false) {
                        window.open(`${activeProduct.uri}`, '_self');
                    } else {
                        setModalVigencyShow(true);
                    }
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleData]);

    const renderIcon = (section, color) => {
        switch (section) {
            case 3:
                return <Car size={25} color={color} />
            case 4:
                return <Motorbike size={24} color={color} />
            default:
                return <></>
        }
    }

    const handleBranch = (product) => {
        handleCurrentBranch(product.section);
        handleActiveProduct(product);
    }

    const handleVisibleModalError = () => {
        setModalShow(!modalShow);
    }

    return (
        <>
            <ErrorModal type={modalType} text={modalText} title={modalTitle} visible={modalShow} handleVisible={handleVisibleModalError} />
            <Modal show={handleShowModalQuotation} showFunction={setHandleShowModalQuotation} type={'confirm'} handleConfirm={() => { window.open(`${activeProduct.uri}`, '_self'); }} title={'Posee una cotización en curso. ¿Desea continuarla?'} handleClose={handleCloseModal} />
            <Modal show={modalPolicyShow} showFunction={() => { setModalPolicyShow(!modalPolicyShow) }} type={'confirm'} handleConfirm={handleDownloadPolicy} title={'Tiene la descarga de un documento pendiente. ¿Desea descargarlo?'} handleClose={handleCloseModalDownloadPolicy} />
            <Modal show={modalVigencyShow} showFunction={() => { setModalVigencyShow(!modalVigencyShow) }} type={'confirm'} handleConfirm={() => { window.open(`${activeProduct.uri}`, '_self'); }} title={`Esta patente tiene un seguro vigente en ATM Seguros, por lo que sólo se podrá visualizar la cotización.`} handleClose={() => { setModalVigencyShow(false) }} />
            <div className={`flex justify-center h-full pb-10 md:w-full sm:max-w-screen-md xl:max-w-screen-lg 3xl:max-w-screen-xl mx-8 md:mx-auto bg-white`}>
                <div id="selector_section" className="w-full">
                    <h1 className={`font-semibold text-2xl lg:text-4xl text-center w-full text-maroon mb-8 md:mb-14 2xl:mb-20 mt-4 ${licenseWindow ? 'visible' : 'hidden'}`}>
                        {
                            arraySections.length > 1 ?
                                '¿Qué seguro vamos a cotizar hoy?'
                                :
                                arraySections[0]?.title
                        }
                    </h1>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-24">
                        <div className="w-full">
                            <div className={`${arraySections.length === 1 ? 'hidden' : 'flex'} flex-row justify-center row_selector_branch gap-x-6 md:gap-x-10`}>
                                {
                                    arraySections.map((e, i) => (
                                        <div key={`unique_key_div_arraySections_${i}`} className="flex flex-col">
                                            <div className="flex flex-col justify-center" onClick={() => { handleBranch(e) }}>
                                                {renderIcon(e.section, activeBranch === e.section ? mainColor : disabledColor)}
                                                <Label title={e.description} className={`uppercase ${activeBranch === e.section ? activeBranchClass : inactiveBranchClass} px-3 mt-3 pb-1`} />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <h2 className="text-center text-black font-semibold text-xl lg:text-3xl my-6">¿Empezamos?</h2>
                            {renderView}
                        </div>
                        <div className="w-full hidden lg:block">
                            <div className="relative flex flex-col h-full justify-top gap-6">
                                <div className="flex flex-col">
                                    {activeBranch === 3 ?
                                        <img src={require('../../assets/img/atm_banner_auto.png')} alt="atm_banner_auto.png" className="h-fit w-auto object-contain" />
                                        :
                                        <img src={require('../../assets/img/atm_banners_Moto.png')} alt="atm_banner_moto.png" className="h-fit w-auto object-contain" />
                                    }
                                </div>
                                <Carousel autoSlide className={"flex flex-col justify-top h-full lg:h-[100px] 2xl:h-[80px]"}>
                                    <div>
                                        <div className="flex flex-col mb-3 text-center">
                                            <p className="font-regular text-sm text-light-grey italic">"Todo fue fácil, muy simple y rápido al contratar todo online"</p>
                                            <p className="font-semibold text-sm text-light-grey">Juan José. Resistencia, Chaco.</p>
                                        </div>
                                        <div className="flex flex-row gap-2 justify-center">
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex flex-col mb-3 text-center">
                                            <p className="font-regular text-sm text-light-grey italic">"Pude cotizar rápidamente."</p>
                                            <p className="font-semibold text-sm text-light-grey">Nadia. Morón, Bs. As.</p>
                                        </div>
                                        <div className="flex flex-row gap-2 justify-center">
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex flex-col mb-3 text-center">
                                            <p className="font-regular text-sm text-light-grey italic">"Me ahorra la molestia de ir hasta un lugar, y puedo contratar el seguro a cualquier hora."</p>
                                            <p className="font-semibold text-sm text-light-grey">Juan Carlos. Ezeiza, Bs. As.</p>
                                        </div>
                                        <div className="flex flex-row gap-2 justify-center">
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={'#FFFFFF'} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex flex-col mb-3 text-center">
                                            <p className="font-regular text-sm text-light-grey italic">"La página es muy intuitiva y fácil de usar. Me encanta."</p>
                                            <p className="font-semibold text-sm text-light-grey">Carla. San Miguel, Bs. As.</p>
                                        </div>
                                        <div className="flex flex-row gap-2 justify-center">
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                            <Star size={sizeStar} color={golden} />
                                        </div>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <About />
            <FAQ />
            <Contact />
        </>
    );
}