import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Close } from '../../assets/icons';

const ErrorModal = ({ type = 'plain', title = '', text = '', visible = false, handleVisible = () => { } }) => {

	useEffect(() => {
		if (visible) {
			document.getElementsByTagName('html')[0].style.overflow = "hidden";
		} else {
			document.getElementsByTagName('html')[0].style.overflow = "auto";
		}
	}, [visible]);

	let border, color = '';

	switch (type) {
		case 'warning':
			border = 'border-yellow-warn'
			color = "text-primary"
			break;
		case 'error':
			border = 'border-red-err'
			color = "text-primary"
			break;
		case 'info':
			border = 'border-blue'
			color = "text-primary"
			break;
		case 'plain':
			border = 'border-light-grey'
			color = "text-primary"
			break;
		default:
			border = 'border-light-grey'
			color = "text-primary";
	}

	return (
		<>
			<div className={`modal bg-black-op z-50 top-0 bottom-0 left-0 right-0 ${visible ? 'visible fixed' : 'hidden'}`} >
				<div className='w-full h-full flex justify-center items-center'>
					<div className={`bg-shiny-pink-lighter w-11/12 md:w-[611px] h-auto rounded-2xl border-2  ${border}`}>
						<div className={`w-full flex justify-end items-center`}>
							<button className='p-2.5' onClick={handleVisible}>
								<Close color={'#7C1338'} size={28} opacity={1.0} />
							</button>
						</div>
						<div className='flex flex-col justify-start items-center text-center gap-5 pb-10 px-6 md:px-8'>
							<div className={`${color} font-bold text-2xl`}>{title}</div>
							<div className={`text-grey font-normal text-base px-6 md:px-0`}>{text}</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

ErrorModal.propTypes = {
	type: PropTypes.string,
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	visible: PropTypes.bool,
	handleVisible: PropTypes.func
};

export default ErrorModal;