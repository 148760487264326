import { useState } from "react";
import { defaultContactForm } from "../config";

const initial = defaultContactForm;

export default function useContactForm() {
    const [contactForm, setContactForm] = useState(initial);

    const modifyContactFormForm = (body) => {
        setContactForm({ ...contactForm, ...body });
    }

    return { contactForm, modifyContactFormForm };
}
