import { useEffect } from "react";
import { pageViewEvent, purchaseEvent, addPaymentInfoEvent, addToCartEvent, logViewItemListEvent, beginCheckoutEvent, refundEvent } from "../services/googleAnalytics";
import { useMyContext } from "../context/global/Context";

export default function useFetchGoogleAnalytics(step = null) {

    const { currentBranch, potencialPolicy, quote, policy } = useMyContext();

    useEffect(() => {
        if (step !== null) {
            let title = '';

            switch (step) {
                case 1:
                    title = 'verify-vehicle-data';
                    break;
                case 2:
                    title = 'choose-plan';
                    break;
                case 3:
                    title = 'complete-personal-data';
                    break;
                case 4:
                    title = 'choose-hiring-method';
                    break;
                case 5:
                    title = 'choose-payment-method';
                    break;
                case 6:
                    title = 'done';
                    break;
                default:
                    title = 'default-page';
                    break;
            }

            logPageView(`/paso-${step}-cotizacion-${currentBranch}`, title);
        }
    }, []);

    const purchase = () => {
        const item = [{
            'item_id': policy.item_id,
            'item_name': policy.item_name,
            'coupon': '',
            'price': policy.price
        }];

        purchaseEvent(item);
    }

    const addPaymentInfo = () => {
        const item = [{
            'item_id': potencialPolicy.quotationId,
            'item_name': `${potencialPolicy.coverageTopTitle} ${potencialPolicy.coverageBottomTitle}`,
            'coupon': '',
            'price': potencialPolicy.feeValue
        }];

        addPaymentInfoEvent(item);
    }

    const logPageView = (page, title) => {
        pageViewEvent(page, title);
    }

    const addToCart = () => {
        const item = [{
            'item_id': potencialPolicy.coverageId,
            'item_name': `${potencialPolicy.coverageTopTitle} ${potencialPolicy.coverageBottomTitle}`,
            'price': potencialPolicy.feeValue
        }];

        addToCartEvent(item);
    }

    const logViewItemList = () => {
        let items = [];

        quote.coverages.map(e => {
            items.push({
                item_id: e.coverageId,
                item_name: `${e.coverageTopTitle} ${e.coverageBottomTitle}`,
                coupon: ''
            });
        });

        logViewItemListEvent(items);
    }

    const beginCheckout = () => {
        const item = [{
            'item_id': potencialPolicy.coverageId,
            'item_name': `${potencialPolicy.coverageTopTitle} ${potencialPolicy.coverageBottomTitle}`,
            'coupon': '',
            'price': potencialPolicy.feeValue
        }];

        beginCheckoutEvent(item);
    }

    const refund = () => {
        refundEvent();
    }

    return { purchase, addPaymentInfo, logPageView, addToCart, logViewItemList, beginCheckout, refund };
}