import { defaultBankList } from "../config";
import { isEmpty, validateCbu } from "./validation";

const scrollToInfo = () => {
    let infoDiv = document.getElementById('info_quotation_start');
    if (infoDiv) {
        infoDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}

const scrollToFaq = () => {
    let element = document.getElementById('preguntas_frecuentes');

    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
        element = document.getElementById('contact_section');

        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
}

const scrollToContact = () => {
    let element = document.getElementById('contact_section');

    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
        window.location.href = '/';

        element = document.getElementById('contact_section');
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}

const scrollToElement = (element) => {
    if (element) {
        const rect = element.getBoundingClientRect();
        const scrollLeft = rect.left + window.scrollX - (window.innerWidth / 2);
        const scrollTop = rect.top + window.scrollY - (window.innerHeight / 2);

        window.scrollTo({
            top: scrollTop,
            left: scrollLeft,
            behavior: 'smooth' // Para hacer el desplazamiento de forma suave
        });
    }
}

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Para hacer el desplazamiento de forma suave
    });
}

const detectMouseMoved = () => {
    return true;
}

const getAssociatedBank = function (cbu) {
    if (isEmpty(cbu) || !validateCbu(cbu)) return '-';

    const code = parseInt(cbu.substr(0, 3), 10);

    const bank = defaultBankList[code];

    if (isEmpty(bank)) {
        return '-';
    }

    return bank;
}

export { scrollToInfo, scrollToFaq, scrollToContact, detectMouseMoved, scrollToElement, scrollToTop, getAssociatedBank };