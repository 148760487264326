import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes, routesMaintenceMode } from "./Routes";
import Loading from "../../components/Loading/Loading";
import { useMyContext } from "../../context/global/Context";
import { useEffect, useState } from "react";
import { isEmpty } from "../../utils/validation";
import { ENV, FACEBOOK_ID_KEY, TAG_MANAGER_KEY } from '../../config/constants';

const router = createBrowserRouter(routes);
const routerMaintenance = createBrowserRouter(routesMaintenceMode);

export default function Router({ children }) {

    const { initialErrors, parameters } = useMyContext();

    const [isLoading, setIsLoading] = useState(true);
    const [routerDom, setRouter] = useState(null);

    useEffect(() => {
        if (ENV === 'prod') {
            const tagManager = TAG_MANAGER_KEY;
            const facebookId = FACEBOOK_ID_KEY;

            const scriptTagManager = document.createElement('script');
            scriptTagManager.src = `https://www.googletagmanager.com/gtag/js?id=${tagManager}`;
            scriptTagManager.async = true;
            document.head.appendChild(scriptTagManager);

            const scriptTagManagerFunc = document.createElement('script');
            scriptTagManagerFunc.innerHTML = `(function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
                new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${tagManager}')`;
            document.head.appendChild(scriptTagManagerFunc);

            const link = document.createElement('link');
            link.rel = 'canonical';
            link.href = window.location.href;
            document.head.appendChild(link);

            const script = document.createElement('script');
            script.innerHTML = `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${facebookId}');
            fbq('track', 'PageView');`;
            document.head.appendChild(script);

            const noScript = document.createElement('noscript');
            const imgNoScript = document.createElement('img');
            imgNoScript.height = 1;
            imgNoScript.width = 1;
            imgNoScript.style = 'display:none';
            imgNoScript.src = `https://www.facebook.com/tr?id=${facebookId}&ev=PageView&noscript=1`;
            noScript.appendChild(imgNoScript);
            document.head.appendChild(noScript);

            const noScriptTagManager = document.createElement('noscript');
            const frameNoScriptTagManager = document.createElement('iframe');
            frameNoScriptTagManager.src = `https://www.googletagmanager.com/ns.html?id=${tagManager}`;
            frameNoScriptTagManager.height = 0;
            frameNoScriptTagManager.width = 0;
            frameNoScriptTagManager.style = 'display:none;visibility:hidden';
            noScriptTagManager.appendChild(frameNoScriptTagManager);
            document.body.prepend(noScriptTagManager);
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(parameters)) {
            const parameter = parameters.find(e => e.tag === 'MODO_MANTENIMIENTO');

            if (!isEmpty(parameter)) {
                if (parameter.enabled === 1)
                    setRouter(routerMaintenance);
                else
                    setRouter(router);
            } else {
                setRouter(router);
            }
        }
    }, [parameters]);

    useEffect(() => {
        if (routerDom !== null)
            setIsLoading(false);
    }, [routerDom]);

    if (isLoading) {
        return (
            <>
                <Loading />
                {
                    initialErrors.length > 0 ?
                        <div className='text-start text-black font-normal px-10'>
                            <p className='text-base mb-6'>Se han encontrado los siguientes errores al cargar el sitio:</p>
                            <ul className='ps-10 list-disc'>
                                {
                                    initialErrors.map((e, i) => {
                                        return <li className='mb-3'>{e}</li>
                                    })
                                }
                            </ul>
                        </div>
                        :
                        <></>
                }
            </>
        );
    }

    return (
        <RouterProvider router={routerDom} fallbackElement={<Loading size={'lg'} />}>
            {children}
        </RouterProvider>
    );
}