import { useState } from 'react';
import { getVehicleUsages, vehicleUsages as usage } from '../services';
import { defaultValueUsage } from '../config';

const initial = defaultValueUsage;

export default function useFetchInitialDataQuotationVehicle() {
    const [vehicleUsages, setVehicleUsages] = useState(initial);

    const fetchVehicleUsages = async (autoCod) => {
        await usage(autoCod)
            .then(response => {
                const usages = response.map(function (v, i) {
                    return { value: v.code, title: v.description };
                });

                setVehicleUsages([...initial, ...usages]);
            });
    }

    const searchVehicleUsages = async (branch, modeloCodigo) => {
        await getVehicleUsages(branch, modeloCodigo)
            .then((response) => {
                const usages = response.map(function (v, i) {
                    return { value: v.code, title: v.description };
                });

                setVehicleUsages([...initial, ...usages]);
            });
    }

    return { vehicleUsages, fetchVehicleUsages, searchVehicleUsages };
}