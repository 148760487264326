import { useEffect, useState } from 'react';
import { checkYearSelected as getCeroKmDisabled } from '../services';
import { isEmpty } from '../utils/validation';

export default function useFetchCeroKm(year) {
    const [ceroKmDisabled, setDisabled] = useState(true);

    useEffect(() => {
        (async () => {
            setDisabled(true);

            if (!isEmpty(year) && year.length === 4) {
                const responseCeroKm = await getCeroKmDisabled(year);

                if (!isEmpty(responseCeroKm.res)) {
                    setDisabled(responseCeroKm.res);
                }
            }
        })();
    }, [year]);

    return { ceroKmDisabled };
}