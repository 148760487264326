import { useState } from "react";
import { defaultCancellationForm } from "../config";

const initial = defaultCancellationForm;

export default function useCancellationForm() {
    const [cancellationForm, setCancellationForm] = useState(initial);

    const modifyCancellationForm = (body) => {
        setCancellationForm({ ...cancellationForm, ...body });
    }

    return { cancellationForm, modifyCancellationForm };
}
