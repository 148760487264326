import { useEffect, useState } from "react";
import { useMyContext } from "../context/global/Context";
import { isEmpty } from "../utils/validation";

export default function useGetSaleCenterSections() {
    const { parametersSaleCenter, products } = useMyContext();

    const [activeBranch, setActiveBranch] = useState();
    const [arraySections, setArraySections] = useState([]);

    useEffect(() => {
        let array = [];

        if (isEmpty(parametersSaleCenter.id)) {
            setArraySections(products);
        } else {
            let arrayAux = [];

            array = parametersSaleCenter.products.map(e => {
                return e.product_id;
            });

            products.forEach(element => {
                if (array.includes(element.id)) {
                    arrayAux.push(element);
                }
            });

            setArraySections(arrayAux);
        }


    }, [parametersSaleCenter, products]);

    return { activeBranch, setActiveBranch, arraySections };
}