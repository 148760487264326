import { useState } from "react";
import { defaultPersonForm } from "../config";

const initial = defaultPersonForm;

export default function usePersonalData() {
    const [dataPerson, setDataPerson] = useState(initial);

    const modifyDataPerson = (body) => {
        setDataPerson({ ...dataPerson, ...body, Direccion: { ...dataPerson.Direccion, ...body.Direccion } });
    }

    return { dataPerson, modifyDataPerson };
}