import { useState } from "react";
import { defaultQuoteForm } from "../config";

const initial = defaultQuoteForm;

export default function useDataForm() {
    const [dataForm, setDataForm] = useState(initial);

    const modifyDataForm = (body) => {
        setDataForm({ ...dataForm, ...body, dataPerson: { ...dataForm.dataPerson, ...body.dataPerson } });
    }

    return { dataForm, modifyDataForm };
}
