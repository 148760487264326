import React from 'react';
import './About.css';
import HandWaved from '../../assets/img/HandWaved.png'
import HandMoney from '../../assets/img/HandMoney.png'
import Phone from '../../assets/img/Phone.png'
import Wheel from '../../assets/img/Wheel.png'

const About = () => {
	return (
		<div className='about w-full bg-light-red py-6'>
			<div className='lg:w-3/4 xl:w-10/12 2xl:w-11/12 m-auto'>
				<div className='w-full flex flex-col justify-center items-center'>
					<div className='flex flex-col justify-center items-center py-14 text-center'>
						<div>
							<h2 className='text-center text-maroon text-2xl lg:text-3xl font-bold leading-7 pb-4'>¿Por qué nos prefieren?</h2>
						</div>
						<div className="text-center text-grey text-base lg:text-xl font-normal leading-7">Descubrí por qué nos eligen una y otra vez.</div>
					</div>
					<div className='item-container flex-col w-full flex 2sm:flex-row justify-between items-start'>
						<div className='flex flex-col w-full h-full justify-center items-center px-6 mb-10 lg:mb-0'>
							<img src={HandWaved} alt='HandWave' className='flex items-start w-[120px]' />
							<div className=' min-h-auto flex flex-col justify-start items-center w-full flex-1 2md:min-h-[250px] py-2 mx-auto'>
								<div>
									<h4 className="text-center text-black text-opacity-100 text-lg font-bold leading-loose tracking-tight pt-2 pb-2">Estamos para vos</h4>
								</div>
								<div className="text-center text-black text-opacity-100 text-base font-normal leading-relaxed tracking-normal letter-spacing-tight mt-2 px-14">Nuestro Servicio Exclusivo para Asegurados está a tu disposición. Obtené atención personalizada fácilmente a través de nuestro teléfono, correo o redes sociales.</div>
							</div>
						</div>
						<div className='flex flex-col w-full h-full justify-center items-center px-6 mb-10 lg:mb-0'>
							<img src={Wheel} alt='Wheel' className='flex items-start w-[120px]' />
							<div className=' min-h-auto flex flex-col justify-start items-center w-full flex-1 2md:min-h-[250px] py-2 mx-auto'>
								<div>
									<h4 className="text-center text-black text-opacity-100 text-lg font-bold leading-loose tracking-tight pt-2 pb-2">Es simple las 24 hs</h4>
								</div>
								<div className="text-center text-black text-opacity-100 text-base font-normal leading-relaxed tracking-normal letter-spacing-tight mt-2 px-14">Simplificamos cada paso para que la gestión de tus seguros sea fácil y rápida en cualquier momento y desde cualquier lugar. En ATM Seguros, ponemos la tecnología a tu servicio.</div>
							</div>
						</div>
						<div className='flex flex-col w-full h-full justify-center items-center px-6 mb-10 lg:mb-0'>
							<img src={Phone} alt='Phone' className='flex items-start w-[120px]' />
							<div className=' min-h-auto flex flex-col justify-start items-center w-full flex-1 2md:min-h-[250px] py-2 mx-auto'>
								<div>
									<h4 className="text-center text-black text-opacity-100 text-lg font-bold leading-loose tracking-tight pt-2 pb-2">La cuota más conveniente</h4>
								</div>
								<div className="text-center text-black text-opacity-100 text-base font-normal leading-relaxed tracking-normal letter-spacing-tight mt-2 px-14">Comprendemos la importancia de ofrecerte planes completos y accesibles que se adapten a tu presupuesto. Recibí la protección que necesitás, cuidando tu bolsillo.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};


export default About;