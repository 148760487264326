import React, { useEffect, useState } from 'react';
import './PersonalDataForm.css';
import PropTypes from 'prop-types';
import { Input, Label, Select } from '../basic';
import { defaultSexSelector } from '../../config';
import { fillWithZeros } from '../../utils/format';
import { DoubleCheck } from '../../assets/icons';
import { useMyContext } from '../../context/global/Context';
import { isEmpty } from '../../utils/validation';

const PersonalDataForm = ({ handleChangeData = () => { }, personData = {}, completedFields = {}, loadingSearch = false }) => {

	const { products, currentBranch } = useMyContext();

	const [minDate, setMinDate] = useState('');
	const [maxDate, setMaxDate] = useState('');

	useEffect(() => {
		if (!isEmpty(products)) {
			const minAge = products.find(e => e.section === currentBranch).min_age;
			const maxAge = products.find(e => e.section === currentBranch).max_age;

			let currentDate = new Date();
			const minYear = currentDate.getFullYear() - minAge;
			currentDate.setFullYear(minYear);
			setMaxDate(`${currentDate.getFullYear()}-${currentDate.getMonth() < 10 ? fillWithZeros(currentDate.getMonth(), '1', 'left') : currentDate.getMonth()}-${currentDate.getDate() < 10 ? fillWithZeros(currentDate.getDate(), '1', 'left') : currentDate.getDate()}`); // la fecha máxima es la fecha actual - la edad mínima (2024 - 18 = 2006)

			currentDate = new Date();
			const maxYear = currentDate.getFullYear() - maxAge;
			currentDate.setFullYear(maxYear);
			setMinDate(`${currentDate.getFullYear()}-${currentDate.getMonth() < 10 ? fillWithZeros(currentDate.getMonth(), '1', 'left') : currentDate.getMonth()}-${currentDate.getDate() < 10 ? fillWithZeros(currentDate.getDate(), '1', 'left') : currentDate.getDate()}`); // la fecha mínima es la fecha actual - la edad máxima (2024 - 65 = 1959)
		}
	}, [products, currentBranch]);

	const iconSize = 24;
	const classNameIcon = 'absolute top-[29%] right-0 md:right-4';

	return (
		<div className='personaldataform w-full py-4'>
			<div className='flex flex-col gap-6 w-full'>
				<div className='font-open-sans font-bold text-2xl text-center md:text-start text-primary mt-10 md:mt-0'>Sobre vos</div>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-6'>
					<Input type='text' label={'DNI *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' handleChange={(e) => { handleChangeData('dni', e.target.value) }} value={personData.DocumentoNumero ?? ''} required maxLength={8} inputIcon={<DoubleCheck color={completedFields.dni.completed ? completedFields.dni.colorActive : completedFields.dni.color} size={iconSize} className={classNameIcon} />} />
					<Input type='text' label={'CUIT'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={personData.Cuit ?? ''} handleChange={(e) => { handleChangeData('cuit', e.target.value) }} maxLength={11} inputIcon={<DoubleCheck color={completedFields.cuit.completed ? completedFields.cuit.colorActive : completedFields.cuit.color} size={iconSize} className={classNameIcon} />} disabled={loadingSearch} />
					<Input type='text' label={'NOMBRE *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={personData.Nombre ?? ''} handleChange={(e) => { handleChangeData('nombre', e.target.value) }} required maxLength={100} inputIcon={<DoubleCheck color={completedFields.nombre.completed ? completedFields.nombre.colorActive : completedFields.nombre.color} size={iconSize} className={classNameIcon} />} disabled={loadingSearch} />
					<Input type='text' label={'APELLIDO *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={personData.Apellido ?? ''} handleChange={(e) => { handleChangeData('apellido', e.target.value) }} maxLength={100} required inputIcon={<DoubleCheck color={completedFields.apellido.completed ? completedFields.apellido.colorActive : completedFields.apellido.color} size={iconSize} className={classNameIcon} />} disabled={loadingSearch} />
					<Input type='date' label={'FECHA DE NACIMIENTO *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white bg-white' className={''} size='w-full' value={personData.FechaNacimiento ?? ''} handleChange={(e) => { handleChangeData('fechaNacimiento', e.target.value) }} required max={maxDate} min={minDate} disabled={loadingSearch} />
					<Select list={defaultSexSelector} className={'border-2 border-b-shiny-pink border-t-white border-x-white w-full px-4 font-bold py-3 placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded focus:outline-none bg-white'} label={<Label title={'condición biológica *'} className={'uppercase text-left mb-1 ml-4 font-bold text-sm text-shiny-pink'} />} selectedValue={personData.Sexo ?? ''} onChange={(e) => { handleChangeData('sexo', e.target.value) }} required disabled={loadingSearch} />
					<div className="grid grid-cols-3 md:flex gap-4">
						<div className="md:w-1/4">
							<Label title={'área *'} className={'text-left mb-1 ml-4 font-bold text-sm text-shiny-pink uppercase'} />
							<div className="flex flex-row items-center">
								<span className='me-1 font-semibold text-black'>0</span>
								<Input name='area_phone' type='text' label={''} placeholder={''} className={'border-2 border-b-shiny-pink border-t-white border-x-white w-full px-1 font-bold py-3 placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded focus:outline-none disabled:pointer-events-none disabled:text-light-grey-disabled disabled:placeholder:text-light-grey-disabled'} size={''} value={personData.Area ?? ''} handleChange={(e) => { handleChangeData('area', e.target.value) }} required inputIcon={<DoubleCheck color={completedFields.area.completed ? completedFields.area.colorActive : completedFields.area.color} size={iconSize} className={'absolute top-[29%] right-0'} />} disabled={loadingSearch} />
							</div>
						</div>
						<div className="col-span-2 md:w-full">
							<Label title={'teléfono celular *'} className={'text-left mb-1 ml-4 font-bold text-sm text-shiny-pink uppercase'} />
							<div className="flex flex-row items-center">
								<span className='me-1 font-semibold text-black'>15</span>
								<Input name='number_phone' type='text' label={''} placeholder={''} className={'border-2 border-b-shiny-pink border-t-white border-x-white w-full px-1 font-bold py-3 placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded focus:outline-none disabled:pointer-events-none disabled:text-light-grey-disabled disabled:placeholder:text-light-grey-disabled'} value={personData.Telefono ?? ''} handleChange={(e) => { handleChangeData('telefono', e.target.value) }} required inputIcon={<DoubleCheck color={completedFields.telefono.completed ? completedFields.telefono.colorActive : completedFields.telefono.color} size={iconSize} className={'absolute top-[29%] right-0'} />} disabled={loadingSearch} />
							</div>
						</div>
					</div>
					<Input type='email' label={'CORREO ELECTRÓNICO *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={personData.EMail ?? ''} handleChange={(e) => { handleChangeData('email', e.target.value) }} required maxLength={100} inputIcon={<DoubleCheck color={completedFields.email.completed ? completedFields.email.colorActive : completedFields.email.color} size={iconSize} className={classNameIcon} />} disabled={loadingSearch} />
				</div>
			</div>
			<div className='flex flex-col gap-6 w-full mt-14 md:mt-10 mb-10'>
				<div className='font-open-sans font-bold text-2xl text-center md:text-start text-primary'>
					¿Dónde vivis?
				</div>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-6'>
					<Input type='text' label={'CÓDIGO POSTAL *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={personData.Direccion.CodigoPostal ?? ''} handleChange={(e) => { handleChangeData('postalCode', e.target.value) }} required maxLength={4} inputIcon={<DoubleCheck color={completedFields.postalCode.completed ? completedFields.postalCode.colorActive : completedFields.postalCode.color} size={iconSize} className={classNameIcon} />} disabled={loadingSearch} />
					<Input type='text' label={'CALLE *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={personData.Direccion.CalleNombre ?? ''} handleChange={(e) => { handleChangeData('calle', e.target.value) }} required maxLength={30} inputIcon={<DoubleCheck color={completedFields.calle.completed ? completedFields.calle.colorActive : completedFields.calle.color} size={iconSize} className={classNameIcon} />} disabled={loadingSearch} />
					<Input type='text' label={'NÚMERO *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={personData.Direccion.CalleNumero ?? ''} handleChange={(e) => { handleChangeData('numero', e.target.value) }} required maxLength={5} inputIcon={<DoubleCheck color={completedFields.numero.completed ? completedFields.numero.colorActive : completedFields.numero.color} size={iconSize} className={classNameIcon} />} disabled={loadingSearch} />
					<Input type='text' label={'PISO'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={personData.Direccion.CallePiso ?? ''} handleChange={(e) => { handleChangeData('piso', e.target.value) }} maxLength={4} inputIcon={<DoubleCheck color={completedFields.piso.completed ? completedFields.piso.colorActive : completedFields.piso.color} size={iconSize} className={classNameIcon} />} disabled={loadingSearch} />
					<Input type='text' label={'DPTO'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' value={personData.Direccion.CalleDepto ?? ''} handleChange={(e) => { handleChangeData('depto', e.target.value) }} maxLength={4} inputIcon={<DoubleCheck color={completedFields.depto.completed ? completedFields.depto.colorActive : completedFields.depto.color} size={iconSize} className={classNameIcon} />} disabled={loadingSearch} />
				</div>
			</div>
		</div>
	);
};

PersonalDataForm.propTypes = {
	handleChangeData: PropTypes.func,
	personData: PropTypes.object,
	completedFields: PropTypes.object,
	loadingSearch: PropTypes.bool
};

export default PersonalDataForm;