import React from 'react';
import { Spinner } from '../../assets/icons';
import './Loading.css';
import PropTypes from 'prop-types';

const Loading = ({ size = 'xs', className = '' }) => {

	let iconSize;

	switch (size) {
		case 'xs':
			iconSize = 20;
			break;
		case 'sm':
			iconSize = 30;
			break;
		case 'lg':
			iconSize = 43;
			break;
		case 'xl':
			iconSize = 75;
			break;
		default:
			iconSize = 0;
			break;
	}
	return (
		<div className={`loading ${className !== '' ? className : 'py-48'}`}>
			<Spinner size={iconSize} color={'#7C1338'} className='animate-spin' />
		</div>
	);
};

Loading.propTypes = {
	size: PropTypes.string,
	className: PropTypes.string
};

export default Loading;