import React, { useEffect } from 'react';
import './Step4.css';
import { ArrowLeft, ArrowRight } from '../../../assets/icons';
import { useMyContext } from '../../../context/global/Context';
import QuoteContract from '../../QuoteContract/QuoteContract';
import { Button, Input, Label } from '../../basic';
import { useState } from 'react';
import ErrorModal from '../../ErrorModal/ErrorModal';
import { isEmpty } from '../../../utils/validation';
import BenefitsComponent from '../../BenefitsComponent/BenefitsComponent';
import { useDataForm, useFetchGoogleAnalytics } from '../../../hooks';
import { formatNumber } from '../../../utils/format';

const Step4 = () => {
  const { functions: { handleStepQuotation, handleOnlineQuotation, handlePhoneQuotation, handleSuccessPhoneQuotation }, parametersSaleCenter, sumAsegDesde, sumAsegHasta, vehicleData, quoteForm } = useMyContext();
  const { dataForm, modifyDataForm } = useDataForm();
  const { logPageView } = useFetchGoogleAnalytics(4);

  useEffect(() => {
    modifyDataForm(quoteForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteForm]);

  useEffect(() => {
    if (!isEmpty(quoteForm.trackingFile)) {
      setLabelTitle(quoteForm.trackingFile.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteForm.trackingFile]);

  const buttonClass = 'uppercase bg-deep-cherry-red 2sm:py-2 py-3 px-4 text-white rounded-lg 2sm:text-lg text-sm font-semibold hover:bg-opacity-85';

  const [loadingOnlineQuotation, setLoadingOnlineQuotation] = useState(false);
  const [loadingPhoneQuotation, setLoadingPhoneQuotation] = useState(false);

  const [modalType, setModalType] = useState('plain');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [onlineDisabled, setOnlineDisabled] = useState(false);
  const [phoneCallDisabled, setPhoneCallDisabled] = useState(false);

  const [modalBenefits, setModalBenefits] = useState(false);

  const [onlineHidden, setOnlineHidden] = useState(false);

  const defaultLabelTitle = 'adjuntar';
  const [labelTitle, setLabelTitle] = useState(defaultLabelTitle);

  useEffect(() => {
    if (parseFloat(vehicleData.Infoauto.sumAssured) >= parseFloat(sumAsegDesde) && parseFloat(vehicleData.Infoauto.sumAssured) <= parseFloat(sumAsegHasta)) {
      setOnlineHidden(true);
    } else {
      setOnlineHidden(false);
    }

  }, [sumAsegDesde, sumAsegHasta, vehicleData.Infoauto.sumAssured]);

  useEffect(() => {
    if (quoteForm.paymentMethod === '01') {
      setOnlineHidden(true);
    }
  }, [quoteForm.paymentMethod]);

  useEffect(() => {
    if (!isEmpty(parametersSaleCenter.id)) {
      setOnlineDisabled(parseInt(parametersSaleCenter.emite_online) === 0);
      setPhoneCallDisabled(parseInt(parametersSaleCenter.disable_phone_call) === 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametersSaleCenter]);

  const handleQuoteContract = async (type) => {
    let validateFile = true;

    if (onlineHidden === true) {
      // validateFile = !isEmpty(dataForm.trackingFile);
    }

    if (validateFile === true) {
      switch (type) {
        case 'O':
          setLoadingOnlineQuotation(true);
          const response = await handleOnlineQuotation(dataForm);

          if (response.error === true) {
            setModalTitle('Atención');
            setModalText(response.msg);
            setModalType('error');
            setModalShow(true);
          }

          setLoadingOnlineQuotation(false);
          break;
        case 'T':
          setLoadingPhoneQuotation(true);
          const responseTel = await handlePhoneQuotation(dataForm);

          logPageView('contratacion-telefonica', 'Contratación telefónica');

          if (responseTel.error === true) {
            setModalTitle('Atención');
            setModalText(responseTel.msg);
            setModalType('error');
            setModalShow(true);
          } else {
            handleSuccessPhoneQuotation();
          }

          setLoadingPhoneQuotation(false);
          break;
        default:
          break;
      }
    } else {
      setModalTitle('Atención');
      setModalText('Debes cargar un archivo válido.');
      setModalType('error');
      setModalShow(true);
    }
  }

  const emptyFile = () => {
    setLabelTitle(defaultLabelTitle);
    modifyDataForm({ trackingFile: null });
    document.getElementById('certificado_rastreo').value = '';
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (!isEmpty(file)) {
      setLabelTitle(file.name);
      modifyDataForm({ trackingFile: file });
    } else {
      emptyFile();
    }
  }

  return (
    <div className='step4'>
      <ErrorModal type={modalType} text={modalText} title={modalTitle} visible={modalShow} handleVisible={() => { setModalShow(!modalShow) }} />
      <BenefitsComponent show={modalBenefits} showFunction={setModalBenefits} />
      <QuoteContract showFunction={setModalBenefits} />
      {/* {onlineHidden === true ?
        <div className='flex flex-col items-center bg-shiny-pink-lighter border-2 rounded-lg md:mt-8 md:px-10 md:py-6'>
          <p className='text-black text-sm text-justify'>{`Adjuntá el certificado`}</p>
          <div className="flex flex-col items-start w-full md:w-[35%] md:mt-6">
            <Input type='file' className={'hidden'} name='certificado_rastreo' accept='.jpg, .jpeg, .png, .pdf' handleChange={(e) => { handleFileUpload(e) }} />
            <div className={`w-full`} onClick={() => {
              document.getElementById('certificado_rastreo').click();
            }}>
              <Label title={<span className='flex flex-row justify-between text-xs self-center items-center select-none whitespace-nowrap'>{labelTitle}<ArrowRight size={18} color='white' /></span>} className={`text-white px-3 md:px-4 py-2 md:py-1 bg-shiny-pink cursor-pointer rounded w-full text-center uppercase font-semibold`} />
            </div>
            <span className='text-xs italic text-black md:mt-2'>Formatos admitidos: .jpg, .jpeg, .png, .pdf</span>
          </div>
        </div>
        :
        <></>
      } */}
      <div className="w-full flex 3sm:flex-row flex-col justify-center items-center md:gap-16 gap-5 md:mt-16 mt-5 px-6 md:px-28 mb-0">
        {
          onlineHidden === false ?
            <div className={`${onlineDisabled === true ? 'hidden' : 'flex'} w-full`}>
              <Button title='contratar online' size={'full'} className={buttonClass} handleClick={() => { handleQuoteContract('O') }} loading={loadingOnlineQuotation} disabled={loadingPhoneQuotation === true ? true : onlineDisabled} />
            </div>
            :
            <></>
        }
        <div className={`flex ${onlineHidden === true || onlineDisabled === true ? 'w-full md:w-[60%]' : 'w-full'}`}>
          <Button title='contratar telefónicamente' size={'full'} className={buttonClass} handleClick={() => { handleQuoteContract('T') }} loading={loadingPhoneQuotation} disabled={loadingOnlineQuotation === true ? true : phoneCallDisabled} />
        </div>
      </div>
    </div>
  );
};

export default Step4;
