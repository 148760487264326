import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Close, DownArrow, Menu, WhatsApp } from '../../assets/icons';
import logo from '../../assets/img/ATM_logo.png';
import './Navbar.css';
import { scrollToContact, scrollToFaq } from '../../utils/functions';
import { useMyContext } from '../../context/global/Context';
import { isEmpty, sanitizePhoneNumber } from '../../utils/validation';
import useGetSaleCenterSections from '../../hooks/useGetSaleCenterSections';

const Navbar = () => {
  const { parametersSaleCenter } = useMyContext();

  const { arraySections } = useGetSaleCenterSections();
  const [opciones, setOpciones] = useState([]);

  useEffect(() => {
    if (!isEmpty(arraySections)) {
      let array = [];

      arraySections.forEach((section, i) => {
        array.push({
          name: section.description,
          href: `/?${section.uri}`
        });
      });

      setOpciones(array);
    }
  }, [arraySections]);

  const navigation = [
    { name: 'HOME', href: '/', current: false, btn: false },
    // { name: 'SOBRE ATM', href: '#', current: false, btn: false },
    {
      name: 'COTIZAR',
      href: '#',
      current: false,
      opciones: opciones,
      btn: false
    },
    { name: 'PREGUNTAS FRECUENTES', href: '#', current: false, btn: false, handleClick: scrollToFaq },
    { name: 'CONTACTO', href: '#', current: false, btn: true, handleClick: scrollToContact },
  ];

  const [menuActive, setMenuActive] = useState(false);
  const [iconColor, setIconColor] = useState('#262626');
  const [responsiveMenu, setResponsiveMenu] = useState(false);

  return (
    <div className={`navbar w-full sticky top-0 ${menuActive ? 'active' : ''} z-30`}>
      <div className='h-20 bg-white px-6 w-full flex-row justify-between items-center inline-flex relative'>
        <div className='flex-row justify-between items-center inline-flex w-full'>
          <div className='h-14 py-1 flex flex-row w-full lg:w-auto justify-between'>
            <Link to={'/'}>
              <img src={logo} alt='ATM' />
            </Link>
            {
              !isEmpty(parametersSaleCenter.vendedor_emi_nombre) ?
                <div className="flex flex-col self-center md:ms-3 text-xs font-thin text-black text-center md:text-start">
                  <p className="font-semibold text-sm">{parametersSaleCenter.vendedor_emi_nombre}</p>
                  <div className="flex flex-row justify-center lg:justify-start">
                    <a href={`https://api.whatsapp.com/send?phone=549${sanitizePhoneNumber(parametersSaleCenter.vendedor_tel)}`} target="_blank" rel="noreferrer" className='inline-flex'><WhatsApp color='#262626' size={12} className='me-1' /> {parametersSaleCenter.vendedor_tel}</a>
                    {
                      isEmpty(parametersSaleCenter.vendedor_emi_matricula) ?
                        <></>
                        :
                        <>
                          <div className='h-0 md:h-auto bg-black border-black w-[1px] mx-1'></div>
                          <p className="text-grey hidden md:block">Matrícula Nº {parametersSaleCenter.vendedor_emi_matricula}</p>
                        </>
                    }
                  </div>
                </div>
                :
                <></>
            }
            <div className='flex self-center lg:hidden'>
              <button onClick={() => setResponsiveMenu(!responsiveMenu)}>
                {!responsiveMenu ?
                  <Menu size={32} color={"#000000"} opacity={1.0} />
                  : <Close size={32} color={"#000000"} opacity={1.0} />
                }
              </button>

              {responsiveMenu &&
                <div className='absolute top-14 right-0 flex flex-col bg-shiny-pink-lighter p-2 rounded justify-between items-center'>
                  {navigation.map((nav, index) => (
                    <div key={index} className='py-1.5 justify-start items-start flex'>
                      {/* COTIZAR */}
                      {
                        nav.opciones ? (
                          <div className='nav-element group py-1.5 flex-col flex-1 items-start flex mr-4  text-black' >
                            <button
                              className='btn px-2.5 py-2 justify-center items-center inline-flex'
                              onClick={() => setMenuActive(!menuActive)}
                            >
                              <div className='text-center text-base font-semibold leading-normal tracking-wide uppercase'>
                                {nav.name}
                              </div>
                              <div className="ms-4">
                                <DownArrow size={14} color={iconColor} />
                              </div>
                            </button>
                            {/* SUBMENU */}
                            <div className={` mt-2 bg-shiny-pink-lighter transition duration-150 ease-in-out origin-top w-full ${menuActive ? 'scale-100 visible flex flex-col justify-center items-center' : 'scale-0 hidden'}`}>
                              {nav.opciones.map((o, i) => (
                                <Link key={i} to={o.href}>
                                  <div className='px-3.5 py-3 justify-center items-center inline-flex text-grey'>
                                    <div className='text-center text-sm font-regular leading-normal tracking-wide'>
                                      {o.name}
                                    </div>
                                  </div>
                                </Link>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className='nav-element py-1.5 justify-start items-start flex flex-1 mr-4 text-black hover:duration-150 '>
                            <Link to={nav.href} onClick={nav.handleClick}>
                              <div className='px-2.5 py-2 justify-center items-center inline-flex'>
                                <div className='text-center text-base font-semibold leading-normal tracking-wide uppercase'>
                                  {nav.name}
                                </div>
                              </div>
                            </Link>
                          </div>
                        )}
                    </div>
                  ))}
                </div>}
            </div>
          </div>

          <div className='lg:relative lg:flex lg:flex-row hidden'>
            {navigation.map((nav, index) => (
              <div key={index} className='py-1.5 justify-start items-start flex mr-4'>
                {/* COTIZAR */}
                {
                  nav.opciones ? (
                    <div className='nav-element group relative py-1.5 flex-col flex-1 items-start flex mr-4 hover:text-shiny-pink text-black' onMouseOver={() => { setIconColor('#DE6180') }} onMouseLeave={() => { setIconColor('#262626') }}>
                      <button
                        className='btn px-2.5 py-2 bg-white justify-center items-center inline-flex'
                        onClick={() => setMenuActive(!menuActive)}
                      >
                        <div className='text-center text-base font-semibold leading-normal tracking-wide uppercase'>
                          {nav.name}
                        </div>
                        <div className="ms-4">
                          <DownArrow size={14} color={iconColor} />
                        </div>
                      </button>
                      <div className='nav-line bg-white h-[2px] rounded-lg mt-[5px] w-full'></div>
                      {/* SUBMENU */}
                      <div className={`absolute top-full left-0 mt-2 bg-white transition duration-150 ease-in-out origin-top min-w-32 grid grid-cols-1 ${menuActive ? 'scale-100' : 'scale-0'}`}>
                        {nav.opciones.map((o, i) => (
                          <Link key={i} to={o.href} onClick={() => setMenuActive(false)}>
                            <div className='px-3.5 py-3 justify-start items-start inline-flex text-grey hover:text-shiny-pink'>
                              <div className='text-center text-sm font-regular leading-normal tracking-wide'>
                                {o.name}
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ) : nav.btn === true ? (
                    <div className='ml-2 justify-start items-start flex flex-1'>
                      <Link to={nav.href} onClick={nav.handleClick}>
                        <div className='px-11 py-2.5 bg-stone rounded-lg justify-center items-center inline-flex hover:bg-dark-pink hover:duration-150'>
                          <div className='text-center text-base font-semibold leading-normal tracking-wide uppercase text-white'>
                            {nav.name}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <div className='nav-element py-1.5 justify-start items-start flex flex-1 mr-4 text-black hover:duration-150 hover:text-shiny-pink '>
                      <Link to={nav.href} onClick={nav.handleClick}>
                        <div className='px-2.5 py-2 bg-white justify-center items-center inline-flex'>
                          <div className='text-center text-base font-semibold leading-normal tracking-wide uppercase'>
                            {nav.name}
                          </div>
                        </div>
                        <div className='nav-line bg-white h-[2px] rounded-lg mt-[5px]'></div>
                      </Link>
                    </div>
                  )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;








