import React, { useState } from 'react';
import './Accordion.css';
import PropTypes from 'prop-types';
import { MinusCircle, PlusCircle } from '../../assets/icons';

const Accordion = ({ title, description }) => {
	const [active, setActive] = useState(false);

	return (
		<div className={`accordion mt-0`}>
			<div className='w-[90%] m-auto'>

				<button className='w-full m-auto py-2' onClick={() => setActive(!active)}>
					<div className='flex flex-row justify-between'>
						<div className={`justify-center items-center flex text-base md:text-lg text-semibold text-left ${active ? 'text-maroon' : 'text-black'}`}>
							<p>{title}</p>
						</div>
						<div className='justify-center items-center flex'>
							{!active ?
								<PlusCircle size={32} color='#262626' opacity={1.0} /> :
								<MinusCircle size={32} color='#7C1338' opacity={1.0} />}

						</div>
					</div>
				</button>
				<div className={`mt-0 max-h-0 overflow-hidden transition-all duration-200 ease-in-out ${active ? 'open max-h-fit my-2' : 'mt-2'}`}>
					<p className='text-normal text-left text-grey text-sm leading-5' dangerouslySetInnerHTML={{ __html: description }}></p>
				</div>
				<div className='bg-grey h-[1px] w-full'></div>
			</div>
		</div>
	);
};

Accordion.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string
};

export default Accordion;