import { useState } from 'react';
import { getBrands as brandService } from '../services';
import { defaultValueBrands } from '../config';

const initial = defaultValueBrands;

export default function useFetchBrands() {
    const [brands, setBrands] = useState(initial);
    const [loadingBrands, setLoadingBrands] = useState(false);

    const getBrands = async (branchId) => {
        setLoadingBrands(true);

        await brandService(branchId)
            .then(response => {
                const brands = response.map(function (v, i) {
                    return { value: v.id, title: v.description };
                });

                setBrands([...initial, ...brands]);
            });

        setLoadingBrands(false);
    }

    return { brands, getBrands, loadingBrands };
}