import React, { useEffect, useRef, useState } from 'react';
import { AlarmBell, ArrowLeft, Cash, CreditCard, Flame, MapPin, Money } from '../../../assets/icons';
import { useMyContext } from '../../../context/global/Context';
import { formatNumber } from '../../../utils/format';
import { Button, Check, Input, Label, Select } from '../../basic';
import './Step1.css';
import { useDataForm, useFetchCities, useFetchGoogleAnalytics, useFetchPaymentMethods } from '../../../hooks';
import PropTypes from 'prop-types';
import { isEmpty, onlyNumber, validateRequiredFields } from '../../../utils/validation';
import ErrorModal from '../../ErrorModal/ErrorModal';
import { Loading } from '../../Loading';
import ReCAPTCHA from 'react-google-recaptcha';
import { scrollToTop } from '../../../utils/functions';
import { GOOGLE_API_KEY, RECAPTCHA_VISIBLE } from '../../../config/constants';

export const Step1 = ({ vehicleUsages = [] }) => {
	const { functions: { handleQuoteForm, newQuote, handleStepQuotation }, vehicleData, quoteForm, quote, sumAsegDesde, sumAsegHasta, minValueGnc, maxValueGnc, currentBranch } = useMyContext();

	const { dataForm, modifyDataForm } = useDataForm();
	const { cities, fetchCities, loadingCities, emptyCities } = useFetchCities();
	const { paymentMethods } = useFetchPaymentMethods();

	useFetchGoogleAnalytics(1);

	const [loadingQuotation, setLoadingQuotation] = useState(false);
	const [errorPostalCode, setErrorPostalCode] = useState(false);
	const [errorMsgPostalCode, setErrorMsgPostalCode] = useState('');
	const [maxGncAllowed, setMaxGncAllowed] = useState(0.0);
	const [minGncAllowed, setMinGncAllowed] = useState(0.0);
	const [disabledButtons, setDisabledButtons] = useState(false);

	const [modalType, setModalType] = useState('plain');
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');
	const [modalShow, setModalShow] = useState(false);

	const recaptcha = useRef();
	const recaptchaVisible = parseInt(RECAPTCHA_VISIBLE) === 1;

	const labelTitleClass = 'uppercase text-dark-pink font-bold text-sm md:mb-4 text-nowrap md:text-wrap';
	const labelTextClass = 'uppercase text-black text-base font-semibold';
	const labelOptionsClass = 'font-bold uppercase text-primary text-xl mx-2 text-nowrap';
	const selectClass = 'text-[#666666] text-base placeholder:font-normal border-t-white border-x-white border-2 border-b-shiny-pink rounded-none min-w-full w-full pe-8 ps-4 md:pe-7 py-3 bg-white overflow-hidden';

	useEffect(() => {
		if (!isEmpty(dataForm.postalCode)) {
			(async () => {
				const response = await fetchCities(dataForm.postalCode);

				setErrorPostalCode(response.error);

				if (response.error === true) {
					setErrorMsgPostalCode(response.msg);
				} else {
					setErrorMsgPostalCode('');
				}
			})();
		} else {
			emptyCities();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataForm.postalCode]);

	useEffect(() => {
		modifyDataForm(quoteForm);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quoteForm]);

	useEffect(() => {
		if (parseFloat(vehicleData.Infoauto.sumAssured) > parseFloat(sumAsegHasta)) {
			setDisabledButtons(true);
			setModalTitle('Lo sentimos');
			setModalText(`La suma asegurada de tu vehículo supera la pauta de suscripción de la compañía.`);
			setModalType('error');
			setModalShow(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sumAsegHasta, vehicleData.Infoauto.sumAssured]);

	useEffect(() => {
		let maxPorcGncAllowed = (parseFloat(maxValueGnc.porc) * parseFloat(vehicleData.Infoauto.sumAssured)) / 100;
		let maxGncAllowed = maxPorcGncAllowed > parseFloat(maxValueGnc.nominal) ? parseFloat(maxValueGnc.nominal) : parseFloat(maxPorcGncAllowed);

		setMaxGncAllowed(maxGncAllowed);

		let minPorcGncAllowed = (parseFloat(minValueGnc.porc) * parseFloat(vehicleData.Infoauto.sumAssured)) / 100;
		let minGncAllowed = minPorcGncAllowed < parseFloat(minValueGnc.nominal) ? parseFloat(minValueGnc.nominal) : parseFloat(minPorcGncAllowed);

		setMinGncAllowed(minGncAllowed);
	}, [maxValueGnc.porc, vehicleData.Infoauto.sumAssured, maxValueGnc.nominal, minValueGnc.porc, minValueGnc.nominal]);

	useEffect(() => {
		if (!isEmpty(paymentMethods) && isEmpty(dataForm.paymentMethod)) {
			// como siempre va a estar el check seleccionado en tarjeta, tengo que setearlo como por defecto cuando no se seleccione nada
			modifyDataForm({ paymentMethod: paymentMethods[0].value });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentMethods, dataForm.paymentMethod]);

	const validateSumAssured = () => {
		if (parseFloat(vehicleData.Infoauto.sumAssured) >= parseFloat(sumAsegDesde) && parseFloat(vehicleData.Infoauto.sumAssured) <= parseFloat(sumAsegHasta)) {
			return dataForm.rastreo;
		}

		return true;
	}

	const handleStartQuotation = async () => {
		const resultValidationSumAssured = validateSumAssured();
		const result = validateRequiredFields('', 'form_step_1');

		let resultPaymentMethod = true;

		if (!isEmpty(paymentMethods)) {
			resultPaymentMethod = !isEmpty(dataForm.paymentMethod);
		}

		const resultValidationGncMax = dataForm.gnc === true ? parseFloat(dataForm.valueGnc) <= parseFloat(maxGncAllowed) : true;
		const resultValidationGncMin = dataForm.gnc === true ? parseFloat(dataForm.valueGnc) >= parseFloat(minGncAllowed) : true;

		if (resultValidationSumAssured === true && resultValidationGncMax === true && resultValidationGncMin === true && resultPaymentMethod === true) {
			setLoadingQuotation(true);

			if (result === true) {
				let token = 'NA';

				if (recaptchaVisible === true) {
					token = await recaptcha.current.executeAsync();
					recaptcha.current.reset();
				}

				const vDataForm = JSON.stringify(dataForm);
				const vQuoteForm = JSON.stringify(quoteForm);
				const validation = quote !== null && vDataForm === vQuoteForm;

				if (validation) { // hace esto para no realizar una cotización nuevamente y levantar los datos del newQuote que ya estén en el storage
					handleStepQuotation(2);
				} else {
					handleQuoteForm({ ...dataForm, ...{ chasisNumber: vehicleData?.Chasis, motorNumber: vehicleData?.Motor, ceroKm: vehicleData?.CeroKm } });

					await newQuote({ usage_code: dataForm.vehicleUsage, postal_code: parseInt(dataForm.postalCode), rastreo: dataForm.rastreo === true ? 'A' : 'N', has_alarm: dataForm.alarma ? 1 : 0, has_gnc: dataForm.gnc ? 1 : 0, value_gnc: parseFloat(dataForm.valueGnc), city_id: dataForm.cityId, recaptcha_token: token, payment_method: dataForm.paymentMethod })
						.then((response) => {
							if (response.error === true) {
								setModalTitle('Lo sentimos');
								setModalText(response.msg);
								setModalType('error');
								setModalShow(true);
							}
						});
				}
			} else {
				console.error('Captcha inválido!!!');
			}
		} else {
			let errors = [];

			if (resultValidationSumAssured === false) {
				errors.push(<li><p>Su vehículo debe contar con rastreo satelital para poder continuar con la cotización.</p></li>);
			}

			if (resultValidationGncMin === false) {
				errors.push(<li><p>El valor de GNC ingresado es por debajo de lo permitido por la compañía.</p></li>);
			}

			if (resultValidationGncMax === false) {
				errors.push(<li><p>El valor de GNC ingresado está por encima de lo permitido por la compañía.</p></li>);
			}

			if (resultPaymentMethod === false) {
				errors.push(<li><p>Debe seleccionar un método de pago válido.</p></li>);
			}

			setModalTitle(<p className='text-primary font-bold text-2xl'>Atención</p>);
			setModalText(
				<ul>
					{
						errors.map(e => { return e })
					}
				</ul>
			);
			setModalType('info');
			setModalShow(true);
		}

		setLoadingQuotation(false);
	}

	const handleChangeGnc = () => {
		modifyDataForm({ gnc: !dataForm.gnc });
	}

	const RenderIcon = ({ method, size, color, className }) => {
		switch (method) {
			case '01':
				return <Cash size={size} color={color} className={className} />;
			case '11':
				return <Money size={size} color={color} className={className} />;
			default:
				return <CreditCard size={size} color={color} className={className} />;
		}
	}

	const handlePaymentMethod = (method) => {
		modifyDataForm({ paymentMethod: method });
	}

	return (
		<div className='step1'>
			<ErrorModal type={modalType} text={modalText} title={modalTitle} visible={modalShow} handleVisible={() => { setModalShow(!modalShow) }} />
			<h3 className="text-primary font-normal text-lg md:text-xl text-center md:text-left my-6 md:my-8 border-t-[1px] md:border-t-0 border-ash-grey md:border-none pt-4 md:pt-0">Estos datos tenemos sobre tu <span className="capitalize font-semibold">{vehicleData?.MarcaDescripcion}</span></h3>
			<section id="vehicle_data_mobile" className='block md:hidden border-b-[1px] border-ash-grey mb-6'>
				<div className="grid grid-flow-col-dense">
					{
						vehicleData.Patente ?
							<div className="flex flex-row gap-2 justify-center">
								<Label title={'Patente'} className={labelTitleClass} />
								<Label title={vehicleData.Patente} className={labelTextClass} />
							</div>
							:
							<></>
					}
					{
						vehicleData.MarcaDescripcion ?
							<div className="flex flex-row gap-2 justify-center">
								<Label title={'Marca'} className={labelTitleClass} />
								<Label title={vehicleData.MarcaDescripcion} className={labelTextClass} />
							</div>
							:
							<></>
					}
				</div>
				<div className="grid grid-cols-1 justify-center my-3">
					{
						vehicleData.Descripcion ?
							<div className="flex flex-row gap-2 justify-center">
								<Label title={'modelo'} className={labelTitleClass} />
								<Label title={vehicleData.Descripcion} className={labelTextClass} />
							</div>
							:
							<></>
					}
				</div>
				<div className="grid grid-flow-col-dense pb-4">
					{
						vehicleData.Anio ?
							<div className="flex flex-row gap-2 justify-center">
								<Label title={'año'} className={labelTitleClass} />
								<Label title={vehicleData.Anio} className={labelTextClass} />
							</div>
							:
							<></>
					}
					{
						vehicleData.Infoauto.sumAssured ?
							<div className="flex flex-row gap-2 justify-center">
								<Label title={'suma asegurada'} className={labelTitleClass} />
								<Label title={formatNumber(vehicleData.Infoauto.sumAssured, 'es-AR', 'currency', 'ARS')} className={labelTextClass} />
							</div>
							:
							<></>
					}
				</div>
			</section>
			<section id="vehicle_data_desktop" className='hidden md:block'>
				<div className="flex flex-row justify-between md:mb-6">
					{
						vehicleData.Patente ?
							<div className="flex flex-col">
								<Label title={'Patente'} className={labelTitleClass} />
								<Label title={vehicleData.Patente} className={labelTextClass} />
							</div>
							:
							<></>
					}
					{
						vehicleData.MarcaDescripcion ?
							<div className="flex flex-col">
								<Label title={'Marca'} className={labelTitleClass} />
								<Label title={vehicleData.MarcaDescripcion} className={labelTextClass} />
							</div>
							:
							<></>
					}
					{
						vehicleData.Descripcion ?
							<div className="flex flex-col">
								<Label title={'modelo'} className={labelTitleClass} />
								<Label title={vehicleData.Descripcion} className={labelTextClass} />
							</div>
							:
							<></>
					}
					{
						vehicleData.Anio ?
							<div className="flex flex-col">
								<Label title={'año'} className={labelTitleClass} />
								<Label title={vehicleData.Anio} className={labelTextClass} />
							</div>
							:
							<></>
					}
					{
						vehicleData.Infoauto.sumAssured ?
							<div className="flex flex-col">
								<Label title={'suma asegurada'} className={labelTitleClass} />
								<Label title={formatNumber(vehicleData.Infoauto.sumAssured, 'es-AR', 'currency', 'ARS')} className={labelTextClass} />
							</div>
							:
							<></>
					}
				</div>
			</section>
			<div className="border-[2px] border-primary rounded-[20px] p-6 mx-4 md:mx-0">
				<div id="form_step_1">
					<div className="mb-12 md:mb-8">
						<h3 className="text-primary opacity-90 font-bold text-xl mb-6">¿Tiene accesorios?</h3>
						<div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-12">
							{
								parseInt(currentBranch) === 3 ?
									<div className="flex mb-6 md:my-0">
										<div className="flex flex-col w-full">
											<div className="flex w-full md:w-min">
												<Check className={'w-full'} checked={dataForm.gnc} label={<div className="flex flex-row items-end"><Flame size={23} color={'#7C1338'} /><Label title={'gnc'} className={labelOptionsClass} /></div>} handleCheck={() => { handleChangeGnc() }} disabled={disabledButtons} />
											</div>
											{
												dataForm.gnc === true ?
													<>
														<span className='text-xs font-semibold text-black mt-6 md:mt-3'>Valor de referencia entre {formatNumber(minGncAllowed, 'es-AR', 'currency', 'ARS')} y {formatNumber(maxGncAllowed, 'es-AR', 'currency', 'ARS')}</span>
														<div className={`mt-3 md:mt-2`}>
															<Input type="number" placeholder={"Valor del GNC *"} className={"text-[#666666] text-base placeholder:font-semibold font-semibold border-t-white border-x-white border-2 border-b-shiny-pink rounded-none min-w-full w-full px-4 py-3 hide-spin-button no-scroll"} value={dataForm.valueGnc} handleChange={(e) => { modifyDataForm({ valueGnc: e.target.value }) }} maxLength={10} required={dataForm.gnc} />
														</div>
													</>
													:
													<></>
											}
										</div>
									</div>
									:
									<></>
							}
							<div className="flex mb-6 md:my-0">
								<div className="flex w-full md:w-min">
									<Check className={'w-full'} checked={dataForm.alarma} label={<div className="flex flex-row items-end"><AlarmBell size={23} color={'#7C1338'} /><Label title={'alarma'} className={labelOptionsClass} /></div>} handleCheck={() => { modifyDataForm({ alarma: !dataForm.alarma }) }} disabled={disabledButtons} />
								</div>
							</div>
							<div className="flex mb-6 md:my-0">
								<div className="flex w-full md:w-min">
									<Check className={'w-full'} checked={dataForm.rastreo} label={<div className="flex items-center"><MapPin size={23} color={'#7C1338'} /><Label title={'rastreo satelital'} className={labelOptionsClass} /></div>} handleCheck={() => { modifyDataForm({ rastreo: !dataForm.rastreo }) }} disabled={disabledButtons} />
								</div>
							</div>
						</div>
					</div>
					<div className="mb-12 md:mb-8">
						<h3 className="text-primary opacity-90 font-bold text-xl mb-4">Sobre vos</h3>
						<div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-12">
							<div className="flex w-full">
								<Select list={vehicleUsages} onChange={(e) => { modifyDataForm({ vehicleUsage: e.target.value }) }} className={selectClass} selectedValue={dataForm.vehicleUsage} required disabled={disabledButtons} />
							</div>
							<div className="flex flex-col w-full my-4 md:my-0">
								<Input type="text" placeholder={"Código postal *"} className={"text-[#666666] text-base placeholder:font-semibold font-semibold border-t-white border-x-white border-2 border-b-shiny-pink rounded-none min-w-full w-full px-4 py-3"} value={dataForm.postalCode} handleChange={(e) => { if (onlyNumber(e.target.value)) modifyDataForm({ postalCode: e.target.value }) }} maxLength={4} required editable={!disabledButtons} />
								{
									errorPostalCode === true ?
										<span className="span_error text-red-err font-semibold text-xs mt-2">{errorMsgPostalCode}</span>
										:
										<></>
								}
							</div>
							<div className="flex flex-row gap-4 w-full">
								<Select list={cities} onChange={(e) => { modifyDataForm({ cityId: e.target.value }) }} className={'data_search text-[#666666] text-base placeholder:font-normal border-t-white border-x-white border-2 border-b-shiny-pink rounded-none min-w-full w-full ps-4 pe-2 py-3 bg-white overflow-hidden'} selectedValue={dataForm.cityId} required disabled={disabledButtons} />
								{
									loadingCities === true ?
										<Loading className={'py-0 self-center'} />
										:
										<></>
								}
							</div>
						</div>
					</div>
					{
						paymentMethods.length === 1 ?
							<></>
							:
							<div className={`${isEmpty(paymentMethods.length) ? 'hidden' : 'block'}`}>
								<h3 className="text-primary opacity-90 font-bold text-xl mb-4">¿Cómo te gustaría pagar tu seguro?</h3>
								<div className="grid grid-cols-1 md:gap-x-12 rounded-md mt-3 md:mt-6">
									<div className='grid grid-cols-1 md:grid-cols-3 md:gap-x-12'>
										{
											paymentMethods.map((method, idx) => (
												<div className={`flex my-2 md:my-0 w-full md:w-auto justify-between md:justify-start`}>
													<div className="flex flex-row">
														<RenderIcon method={method.value} color={'#7C1338'} size={23} className={'me-2'} />
														<p className={'text-base font-semibold self-center text-center md:text-start uppercase text-primary'}>{method.title}</p>
													</div>
													<div className='self-center'>
														<Check checked={dataForm.paymentMethod === method.value ? true : false} handleCheck={() => { handlePaymentMethod(method.value) }} className='mx-2 md:mx-3' appearance='normal' />
													</div>
												</div>
											))
										}
									</div>
								</div>
							</div>
					}
				</div>
			</div>
			<div className="flex flex-col-reverse md:flex-row mt-8 md:mt-4 pt-1 w-full md:w-3/4 mx-auto justify-center">
				<div className="flex flex-row w-full md:w-auto justify-center md:justify-start">
					<Button title="volver al paso anterior" className={'border-0 py-2 font-semibold text-base uppercase bg-white text-light-grey-disabled flex flex-row items-center'} icon={<ArrowLeft size={23} color={'#A1AEB7'} className={'ms-4'} />} size={'auto'} handleClick={() => { scrollToTop(); window.open('/', '_self'); }} disabled={loadingQuotation} />
				</div>
				{
					disabledButtons === true ?
						<></>
						:
						<div className="w-full md:w-3/6 md:ms-10 px-4 md:px-0 mb-4 md:mb-0">
							<Button title="cotizar" className={'uppercase bg-primary text-white rounded-md py-2 hover:bg-opacity-85'} size={'full'} handleClick={handleStartQuotation} loading={loadingQuotation} />
						</div>
				}
			</div>
			{
				recaptchaVisible === true ?
					<div className="flex flex-row">
						<ReCAPTCHA ref={recaptcha} sitekey={GOOGLE_API_KEY} size='invisible' />
					</div>
					:
					<></>
			}
		</div>
	);
};

Step1.propTypes = {
	vehicleUsages: PropTypes.array
};