import { useEffect, useState } from "react";
import { useMyContext } from "../context/global/Context";

export default function useFetchCoverages() {
    const { quote } = useMyContext();

    const [coverages, setCoverages] = useState([]);
    const [loadingCoverages, setLoadingCoverages] = useState(true);

    useEffect(() => {
        setLoadingCoverages(true);
        if (quote) {
            setCoverages(quote.coverages);
        }
        setLoadingCoverages(false);
    }, [quote]);

    return { coverages, loadingCoverages };
}