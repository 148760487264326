import React from 'react';
import './Check.css';
import PropTypes from 'prop-types';
import { Label } from '../Label';
import { isEmpty } from '../../../utils/validation';

const Check = ({ label, checked, handleCheck, icon, className = '', disabled = false, appearance = 'normal' }) => {
	let checkComponent = <></>;

	switch (appearance) {
		case 'normal':
			checkComponent =
				<div className={`flex items-center ${disabled === true ? 'pointer-events-none opacity-45' : 'cursor-pointer'} cm-toggle-wrapper transition-all`}>
					<div className={`flex items-center rounded-full w-10 h-6 border-2 ${checked === true ? 'bg-green border-green' : 'bg-light-grey-2 border-grey'}`} onClick={handleCheck}>
						<div className={`mx-auto rounded-full transform duration-300 ease-in-out ${checked === true ? 'bg-white translate-x-2 w-4 h-4' : '-translate-x-2 bg-grey w-3 h-3'}`}></div>
					</div>
				</div>;
			break;
		case 'always-checked':
			checkComponent =
				<div className={`flex items-center ${disabled === true ? 'pointer-events-none opacity-45' : 'cursor-pointer'} cm-toggle-wrapper transition-all`}>
					<div className={`flex items-center rounded-full w-10 h-6 border-2 bg-green border-green`} onClick={handleCheck}>
						<div className={`mx-auto rounded-full transform duration-300 ease-in-out bg-white w-4 h-4 ${checked === true ? 'translate-x-2' : '-translate-x-2'}`}></div>
					</div>
				</div>;
			break;
		case 'disabled':
			checkComponent =
				<div className={`flex items-center ${disabled === true ? 'pointer-events-none opacity-45' : 'cursor-pointer'} cm-toggle-wrapper transition-all`}>
					<div className={`flex items-center rounded-full w-10 h-6 border-2 bg-light-grey-2 border-grey`} onClick={handleCheck}>
						<div className={`mx-auto rounded-full transform duration-300 ease-in-out bg-grey w-3 h-3 ${checked === true ? 'translate-x-2' : '-translate-x-2'}`}></div>
					</div>
				</div>;
			break;
		default:
			break;
	}

	let iconComponent = icon !== undefined ? icon : <></>;
	let labelComponent;

	if (typeof label === 'string' && !isEmpty(label)) {
		labelComponent =
			<React.Fragment>
				<Label
					className={`flex ${disabled === true ? 'text-grey' : 'text-maroon'} mr-3 ml-2 items-center`}
					title={label}
					icon={iconComponent}
				/>
			</React.Fragment>;
	} else if (React.isValidElement(label)) {
		labelComponent = <React.Fragment>{label}</React.Fragment>
	} else {
		labelComponent = <></>;
	}

	let mainComponent =
		<div className="flex flex-row justify-between md:justify-center w-full">
			{labelComponent}
			{checkComponent}
		</div>

	return (
		<div className={`check ${className}`}>
			{mainComponent}
		</div >
	);
};

Check.propTypes = {
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	checked: PropTypes.bool,
	handleCheck: PropTypes.func,
	icon: PropTypes.element,
	className: PropTypes.string,
	appearance: PropTypes.string,
};

export default Check;