import React from 'react';
import { DoubleCheck } from '../../assets/icons';
import { useMyContext } from '../../context/global/Context';
import { Input, Label } from '../basic';
import './ConfirmForm.css';
import PropTypes from 'prop-types';

const ConfirmForm = ({ }) => {

	const { vehicleData, personData } = useMyContext();

	const colorIcon = '#9EC54C';

	const className = 'uppercase border-2 border-b-shiny-pink border-t-white border-x-white ps-4 pe-14 font-semibold py-3 w-full placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded pointer-events-none focus:outline-none';

	return (
		<div className='confirmform flex w-full md:w-auto px-6 md:px-0'>
			<div className="flex flex-col md:flex-row md:flex-wrap w-full md:w-auto justify-between gap-y-10 md:gap-y-5">
				<Input type='text' label={'MARCA'} placeholder={''} className={className} size='w-full' value={vehicleData?.MarcaDescripcion} inputIcon={<DoubleCheck size={24} color={vehicleData?.MarcaDescripcion === '' ? 'black' : colorIcon} className='absolute top-[29%] right-6' />} editable={false} />
				<Input type='text' label={'NOMBRE Y APELLIDO'} placeholder={''} className={className} size='w-full' value={`${personData?.Nombre} ${personData?.Apellido}`} inputIcon={<DoubleCheck size={24} color={personData?.Apellido === '' ? 'black' : colorIcon} className='absolute top-[29%] right-6' />} editable={false} />
				<Input type='text' label={'MODELO'} placeholder={''} className={className} size='w-full' value={vehicleData?.Descripcion} inputIcon={<DoubleCheck size={24} color={vehicleData?.Descripcion === '' ? 'black' : colorIcon} className='absolute top-[29%] right-6' />} editable={false} />
				<Input type='text' label={'DNI'} placeholder={''} className={className} size='w-full' value={personData?.DocumentoNumero} inputIcon={<DoubleCheck size={24} color={personData?.DocumentoNumero === '' ? 'black' : colorIcon} className='absolute top-[29%] right-6' />} editable={false} />
				<Input type='text' label={'PATENTE'} placeholder={''} className={className} size='w-full' value={vehicleData?.Patente} inputIcon={<DoubleCheck size={24} color={vehicleData?.Patente === '' ? 'black' : colorIcon} className='absolute top-[29%] right-6' />} editable={false} />
				<Input type='text' label={'DIRECCIÓN'} placeholder={''} className={className} size='w-full' value={`${personData?.Direccion.CalleNombre} ${personData?.Direccion.CalleNumero}`} inputIcon={<DoubleCheck size={24} color={personData?.Direccion.CalleNombre === '' ? 'black' : colorIcon} className='absolute top-[29%] right-6' />} editable={false} />
			</div>
		</div>
	);
};

export default ConfirmForm;