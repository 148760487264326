"use client";
import React from 'react';
import './Tooltip.css';
import PropTypes from 'prop-types';
import { CaretDown } from '../../assets/icons';

const Tooltip = ({ reference = '', text = '' }) => {
	return (
		<>
			<div id={reference} className="tooltip hidden flex-row bg-light-grey absolute px-3 py-2 text-white text-[11px] leading-4 tracking-normal w-[160px] rounded-lg bottom-6 -translate-x-[43%] mb-1 ml-[1.5px]">
				<p>{text}</p>
				<div className="absolute bottom-0 left-0 right-0 translate-y-[10px]">
					<CaretDown size={17} color='#667285' />
				</div>
			</div>
		</>
	);
};

Tooltip.propTypes = {
	reference: PropTypes.string,
	text: PropTypes.string,
};

export default Tooltip;